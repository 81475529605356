import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, Grid, IconButton, Stack, Typography} from "@mui/material";
import questionType from "../assets/data/typeMap";
import {QuestionSwitch} from "./Questions/QuestionSwitch";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Close} from "@mui/icons-material";
import pleshDark from "../theme/pleshDark";
import {homeButtons} from "../config";
import {find} from "lodash";


export const PinnedQuestions = ({questions, userData}) => {
    const [selected, setSelected] = useState('')
    const {id} = useParams()

    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(() => {
        selected !== '' ? getElementPlaying() : setElementPlaying({})
        console.info(questions.docs)
    }, [selected]);

    function getElementPlaying() {
        const selectedObj = find(homeButtons, ['id',selected])
        if(selected && !selectedObj) {
            firestore.collection('users/plesh/events/' + id + '/queue').doc(selected).onSnapshot(s => {
                setElementPlaying({...s.data(), id: s.id})
                return s.data()
            })
        } else {
            setElementPlaying({...selectedObj})
        }
    }

    console.log("selected:",selected)
    console.log("elementPlaying:",elementPlaying)

    return (
        <Box mt={3} justifyContent={'center'} display={'flex'}>
            {selected !== '' &&
                <Dialog open={selected !== ''} onClose={() => setSelected('')} maxWidth={'md'}
                        fullWidth={true}
                        fullScreen={true}
                >
                    <DialogContent id={'a' + selected} /*id={'pinnedQuestionDialogBg'}*/>
                        <Box sx={{textAlign: 'right'}}>
                            <IconButton onClick={() => setSelected('')}
                                        sx={{
                                            color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : ''
                                        }}
                            >
                                <Close/>
                            </IconButton>
                        </Box>
                        {elementPlaying.id !== 'mappa' &&
                            <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                <img src={'/images/premium-logo.svg'} alt={'logo'}
                                    style={{width: '70%', marginTop: '2vh'}}/>
                            </Box>
                        }

                        {/*<Typography textAlign={'center'} variant={'h5'}
                                sx={{color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : ''}}
                    >
                        {elementPlaying.domanda}
                    </Typography>*/}
                        <QuestionSwitch elementPlaying={elementPlaying} />
                        {elementPlaying.id !== 'mappa' && elementPlaying.id !== 'food' &&
                            <Box display={'flex'} width={'100%'} mt={5}>
                                <img src={'/images/open-logo.svg'} alt={'logo'} style={{
                                    width: '60px',
                                    margin: 'auto',
                                    marginTop:'auto'
                                }}/>
                            </Box>
                        }

                    </DialogContent>
                </Dialog>}

            <Grid container justifyContent={'center'} width={'100%'} height={'fit-content'} spacing={1}>
                {homeButtons.concat(questions.docs.sort((a, b) => a.data().position - b.data().position))
                    // Filtra gli elementi con la condizione
                    .filter(button => !(button.data && typeof button.data === 'function' && button?.data()?.domanda === 'DEDICHE' && (userData.displayName==='staff' || userData.displayName === 'guest')))
                    .map((button, index, filteredButtons) => (
                        <Grid
                            key={button.id}
                            item
                            xs={filteredButtons.length === 4 ? 6 : 4} // 2x2 se sono 4 elementi, altrimenti 3x2
                            sx={{cursor: 'pointer', maxWidth: '120px'}}
                            onClick={() => setSelected(button.id)}
                        >
                            <Box mb={1} mx={1} position={'relative'} sx={{border: '2px solid #fff', aspectRatio: '1/1'}}>
                                <Box sx={{
                                    background: 'transparent',
                                    width: '100%',
                                    position: 'absolute',
                                    top: 0
                                }}
                                     minHeight={'25px'}
                                     borderRadius={'0 18px'}>
                                    <Typography variant={'caption'} fontStyle={'normal'} fontWeight={'normal'}
                                                textTransform={'none'} color={'white'}>
                                        {button.label || button?.data()?.domanda || ''}
                                    </Typography>
                                </Box>
                                <Box sx={{background: 'transparent', width: '100%',}} minHeight={'90px'}
                                     borderRadius={'0 18px'}>
                                    <Box sx={{position: 'absolute', bottom: !!button.img ? '10%' : '10%', left: 0, right: 0}}>
                                        {button.img || button.icon || questionType[button.data().tipo].img || questionType[button.data().tipo].icon}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
            </Grid>

            {/*<Stack spacing={4}>
                {
                    questions.docs.sort((a, b) => a.data().position - b.data().position).map((q, i) => {
                            return <Button key={i} variant={'home-question'} startIcon={questionType[q.data().tipo].icon}
                                           sx={{
                                               background: q.data().tipo === 'color' ? q.data().color : '',
                                               color: q.data().tipo === 'color' ? pleshDark.palette.getContrastText(q.data().color) : ''
                                            }}
                                    onClick={() => setSelected(q.id)}>
                                {q.data().domanda}
                            </Button>
                        }
                    )
                }
            </Stack>*/}
        </Box>
    )
}