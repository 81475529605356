import React, {useEffect, useState} from "react";
import {auth, firebase, firestore} from "../firebase/clientApp";
import VoteContent from "../components/VoteContent";
import {useParams} from "react-router-dom";
import {Box, Container, Typography} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import axios from "axios";
import {API_URL} from "../config";

export default function Privacy() {


    return (
        <Box sx={{position:'relative', display:'flex', flexDirection:'column', alignItems:'center'}}>
            <img src={'/images/premium-logo.svg'} alt={'logo'} style={{width:'80%', marginTop:'8vh'}} />
            <Typography pt={2} color={'primary'} fontWeight={'bold'}>
                TERMINI E CONDIZIONI PARTECIPAZIONE EVENTO
            </Typography>
            <Box sx={{width:'90vw'}}>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    Ti preghiamo di leggere attentamente i termini e le condizioni qui di seguito riportati. <br/>
                    Partecipando all’evento, con la presente
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'bold'} textAlign={'center'}>
                    conferma <br/>
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    1) di essere maggiorenne (almeno 18 anni) e di essere consapevole e accettare che l’accesso ai Minori non
                    è consentito.
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    2) di essere consapevole e accettare che l’accesso all’evento sarà consentito soltanto agli invitati registrati.
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    3) di essere stato informato che durante l’evento potrebbero essere scattate delle fotografie /o girati dei
                    video relativi all’evento stesso ai fini di comunicazione aziendale interna ed esterna e nelle quali
                    potrebbero essere coinvolti nel contesto dell’evento anche le immagini dei partecipanti all’evento stesso
                    (“Immagini”).
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    4) di essere stato informato che partecipando all’evento, senza che venga espressamente comunicato al
                    personale quanto previsto dal punto 6 sotto, si autorizza Philip Morris Italia S.r.l., società a socio unico,
                    avente sede legale in Roma in Via Po 11-13-15, Cod. Fisc. 06657521008 e le altre affiliate del gruppo Philip
                    Morris International (nell’insieme “PM Italia”), in forma completamente gratuita e senza necessità di
                    ulteriore avviso, ad acquisire tali Immagini anche mediante video e foto ed utilizzare mediante
                    pubblicazione, distribuzione, o esposizione, in materiali aziendali, attraverso tutti i mezzi di comunicazione
                    interna ed esterna a PM Italia anche on-line (come ad esempio il sito web OPENINSIEME.com), in tutto il
                    mondo le proprie immagini, voce, posa, e tutto ciò che è il risultato della propria partecipazione all’evento
                    e/o ogni altro utilizzo che si renderà necessario; tale autorizzazione potrà essere revocata per usi futuri
                    delle proprie Immagini ma si accetta che lo stesso non sarà applicabile alle Immagini facenti parte di
                    materiale già acquisito per l’evento e già pubblicato, distribuito, esposto o comunque in uso.
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    5) di essere stato informato con il presente atto che nel partecipare all’evento, i propri dati personali
                    saranno trattati da PM Italia, quale Titolare del trattamento, nel rispetto dei principi di liceità, correttezza e
                    trasparenza ed esclusivamente per le finalità connesse all’utilizzo delle Immagini, come descritto al punto 3.
                    L’invitato viene così informato che potrà esercitare i diritti contemplati dagli articoli da 15 a 22 del
                    Regolamento Privacy e secondo la normativa italiana applicabile, scrivendo a servizioclienti@iqos.it e che
                    l’informativa privacy completa è disponibile al seguente sito <a style={{color:'#fff'}} href={'https://www.pmiprivacy.com/italy/it/general-enquirer/'} target={'_blank'}>https://www.pmiprivacy.com/italy/it/general-enquirer/</a>. I predetti dati personali potranno essere trasmessi, per le finalità di cui sopra, anche all’estero, in
                    Paesi non in possesso dei medesimi standard per la protezione dei dati presenti in Italia. In tal caso, PM
                    Italia garantisce che il trasferimento dei dati sarà adeguatamente protetto.
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    6) di essere stato informato che è possibile segnalare prima dell’Evento e durante lo stesso al personale
                    presente presso la struttura dell’Evento la propria volontà di non voler essere ripreso e/o fotografato in
                    occasione di tali Immagini relative all’Evento e che, in assenza di tale segnalazione, sarà valido e operativo
                    quanto previsto dai presenti termini e condizioni quanto all’acquisizione ed utilizzo di tali riprese e
                    fotografie dell’Evento nelle quali è stato ripreso e/o fotografato l’invitato.
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    7) di attenersi strettamente alle regole di sicurezza e di condotta previste dalle strutture ospitanti nonché di
                    rispettare i diritti di proprietà intellettuale, la riservatezza e la privacy di terzi.
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    8) di essere stato informato che è fatto divieto di registrare e/o riprendere in qualsiasi modo lo Spettacolo
                    dell’Artista che si esibirà in una performance musicale durante l’evento; l’immagine, voce e
                    rappresentazione dell’Artista sono protetti da diritti di proprietà intellettuale e qualunque utilizzo e/o
                    sfruttamento non autorizzato degli stessi in qualunque forma e modalità, compresa la realizzazione e
                    diffusione di video e foto, qualora l’invitato decidesse spontaneamente di effettuarli, è soggetto ad azioni a
                    tutela di tali diritti da parte dell'Artista.
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    9) di assumersi esclusiva responsabilità per ogni comportamento tenuto durante l’evento, manlevando PM
                    Italia da ogni conseguente responsabilità e/o pretesa, salvo i casi di dolo o colpa grave di PM Italia.
                </Typography>
                <Typography pt={2} color={'#fff'} fontWeight={'normal'} textAlign={'left'}>
                    10) di essere consapevole e accettare che i presenti termini e condizioni sono regolati dalla legge italiana e
                    che per qualsiasi controversia relativa all'interpretazione, esecuzione e risoluzione del Contratto sarà
                    esclusivamente competente il Foro di Roma.
                </Typography>
            </Box>

            <img src={'/images/open-logo.svg'} alt={'logo'} style={{width:'80px', position:'relative', marginTop:'2vh', marginBottom:'2vh'}}/>
        </Box>
    )
}
