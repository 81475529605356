import {
    Box,
    Collapse,
    List, ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography
} from "@mui/material";
import {ExpandLess, ExpandMore, StarBorder} from "@mui/icons-material";
import React, {useState} from "react";
import WelcomeCollapseContent from "./foodComponents/WelcomeCollapseContent";
import DinnerCollapseContent from "./foodComponents/DinnerCollapseContent";
import DolciCollapseContent from "./foodComponents/DolciCollapseContent";
import DrinkCollapseContent from "./foodComponents/DrinkCollapseContent";


export default function Food() {
    const [open, setOpen] = useState({
        welcome: false,
        dinner: false,
        dolci: false,
        drink: false
    });

    // Funzione per gestire il toggle di un elemento
    const handleClick = (key) => {
        setOpen((prevOpen) => ({
            ...prevOpen,
            [key]: !prevOpen[key],
        }));
    };

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'}
             overflow={'hidden'}>
            <Typography variant={'h5'} color={'primary'} fontWeight={'bold'} mt={'5vh'}>
                STREET FOOD
            </Typography>
            <List
                sx={{
                    width: '100%',
                    bgcolor: 'transparent',
                    marginTop: '2vh',
                    maxHeight: '60vh', // Altezza massima della lista
                    overflowY: 'auto', // Scrolling verticale abilitato
                    overflowX: 'hidden', // Disabilita scrolling orizzontale
                }}
            >
                {/* WELCOME */}
                <ListItemButton onClick={() => handleClick('welcome')} sx={{
                    borderBottom: open.welcome ? '1px solid #03d1d1' : '1px solid #b6b6b6',
                    padding: 0,
                    paddingBottom: '5px'
                }}>
                    <Typography mr={'auto'} variant={'h5'} color={open.welcome ? 'primary' : '#fff'}>
                        Welcome
                    </Typography>
                    {open.welcome ? <ExpandLess sx={{color: '#03d1d1'}}/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={open.welcome} timeout="auto" unmountOnExit>
                    <WelcomeCollapseContent />
                </Collapse>

                {/* LIGHT DINNER */}
                <ListItemButton onClick={() => handleClick('dinner')}
                                sx={{
                                    borderBottom: open.dinner ? '1px solid #03d1d1' : '1px solid #b6b6b6',
                                    padding: 0,
                                    paddingBottom: '5px',
                                    paddingTop: '20px'
                                }}>
                    <Typography mr={'auto'} variant={'h5'} color={open.dinner ? 'primary' : '#fff'}>
                        Light Dinner
                    </Typography>
                    {open.dinner ? <ExpandLess sx={{color: '#03d1d1'}}/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={open.dinner} timeout="auto" unmountOnExit>
                    <DinnerCollapseContent />
                </Collapse>

                {/* DOLCI */}
                <ListItemButton onClick={() => handleClick('dolci')} sx={{
                    borderBottom: open.dolci ? '1px solid #03d1d1' : '1px solid #b6b6b6',
                    padding: 0,
                    paddingBottom: '5px',
                    paddingTop: '20px'
                }}>
                    <Typography mr={'auto'} variant={'h5'} color={open.dolci ? 'primary' : '#fff'}>
                        Dolci
                    </Typography>
                    {open.dolci ? <ExpandLess sx={{color: '#03d1d1'}}/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={open.dolci} timeout="auto" unmountOnExit>
                    <DolciCollapseContent/>
                </Collapse>

                {/* DRINKS */}
                <ListItemButton onClick={() => handleClick('drink')} sx={{
                    borderBottom: open.drink ? '1px solid #03d1d1' : '1px solid #b6b6b6',
                    padding: 0,
                    paddingBottom: '5px',
                    paddingTop: '20px'
                }}>
                    <Typography mr={'auto'} variant={'h5'} color={open.drink ? 'primary' : '#fff'}>
                        Drink
                    </Typography>
                    {open.drink ? <ExpandLess sx={{color: '#03d1d1'}}/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={open.drink} timeout="auto" unmountOnExit>
                    <DrinkCollapseContent />
                </Collapse>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'100%'} mt={5}>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
                        <img src={'/images/icons/quality-icon.svg'} width={'15px'}/>
                        <Typography color={'#03d1d1'} fontSize={14} fontWeight={'bold'} ml={1}>
                            QUALITÀ E ATTENZIONE ALL’AMBIENTE
                        </Typography>
                    </Box>

                    <Typography fontSize={12} color={'#03d1d1'} fontWeight={'normal'} textAlign={'center'} sx={{textTransform:'uppercase'}} mt={2}>
                        Il servizio è realizzato con prodotti riciclabili
                        a tutela della salvaguardia dell’ambiente.
                    </Typography>
                </Box>
            </List>
            <img src={'/images/open-logo.svg'} alt={'logo'} style={{
                width: '60px',
                margin: 'auto',
                marginTop: '5vh',
                position:'relative',
            }}/>

        </Box>

    );
}