export const premiumOptions = [
    { label: "IQOS PREMIUM PARTNER ABBADIA S.SALVATORE", value: "abbadia-s.salvatore" },
    { label: "IQOS PREMIUM PARTNER ABBIATEGRASSO", value: "abbiategrasso" },
    { label: "IQOS PREMIUM PARTNER ACCURSIO", value: "accursio" },
    { label: "IQOS PREMIUM PARTNER ACERRA", value: "acerra" },
    { label: "IQOS PREMIUM PARTNER ACI CASTELLO", value: "aci-castello" },
    { label: "IQOS PREMIUM PARTNER ACIREALE", value: "acireale" },
    { label: "IQOS PREMIUM PARTNER ACQUAPENDENTE", value: "acquapendente" },
    { label: "IQOS PREMIUM PARTNER ACQUASANTA", value: "acquasanta" },
    { label: "IQOS PREMIUM PARTNER ACQUI TERME", value: "acqui-terme" },
    { label: "IQOS PREMIUM PARTNER ACRI", value: "acri" },
    { label: "IQOS PREMIUM PARTNER ADRANO", value: "adrano" },
    { label: "IQOS PREMIUM PARTNER ADRIA", value: "adria" },
    { label: "IQOS PREMIUM PARTNER ADRO", value: "adro" },
    { label: "IQOS PREMIUM PARTNER AFRAGOLA", value: "afragola" },
    { label: "IQOS PREMIUM PARTNER AGIRA", value: "agira" },
    { label: "IQOS PREMIUM PARTNER AGRIGENTO", value: "agrigento" },
    { label: "IQOS PREMIUM PARTNER AGROPOLI", value: "agropoli" },
    { label: "IQOS PREMIUM PARTNER AIROLA", value: "airola" },
    { label: "IQOS PREMIUM PARTNER ALA", value: "ala" },
    { label: "IQOS PREMIUM PARTNER ALASSIO", value: "alassio" },
    { label: "IQOS PREMIUM PARTNER ALBA", value: "alba" },
    { label: "IQOS PREMIUM PARTNER ALBA ADRIATICA", value: "alba-adriatica" },
    { label: "IQOS PREMIUM PARTNER ALBANO LAZIALE", value: "albano-laziale" },
    { label: "IQOS PREMIUM PARTNER ALBENGA", value: "albenga" },
    { label: "IQOS PREMIUM PARTNER ALBEROBELLO", value: "alberobello" },
    { label: "IQOS PREMIUM PARTNER ALBIGNASEGO", value: "albignasego" },
    { label: "IQOS PREMIUM PARTNER ALCAMO", value: "alcamo" },
    { label: "IQOS PREMIUM PARTNER ALESSANDRIA", value: "alessandria" },
    { label: "IQOS PREMIUM PARTNER ALGHERO", value: "alghero" },
    { label: "IQOS PREMIUM PARTNER ALMENNO SAN BARTOLOMEO", value: "almenno-san-bartolomeo" },
    { label: "IQOS PREMIUM PARTNER ALTAMURA", value: "altamura" },
    { label: "IQOS PREMIUM PARTNER ALTARELLO", value: "altarello" },
    { label: "IQOS PREMIUM PARTNER ALTAVILLA IRPINA", value: "altavilla-irpina" },
    { label: "IQOS PREMIUM PARTNER ALTO RENO TERME", value: "alto-reno-terme" },
    { label: "IQOS PREMIUM PARTNER AMANTEA", value: "amantea" },
    { label: "IQOS PREMIUM PARTNER AMARI", value: "amari" },
    { label: "IQOS PREMIUM PARTNER ANACAPRI", value: "anacapri" },
    { label: "IQOS PREMIUM PARTNER ANAGNI", value: "anagni" },
    { label: "IQOS PREMIUM PARTNER ANAGNINA", value: "anagnina" },
    { label: "IQOS PREMIUM PARTNER ANCONA", value: "ancona" },
    { label: "IQOS PREMIUM PARTNER ANDORA", value: "andora" },
    { label: "IQOS PREMIUM PARTNER ANDRIA", value: "andria" },
    { label: "IQOS PREMIUM PARTNER ANGRI", value: "angri" },
    { label: "IQOS PREMIUM PARTNER ANZANO DEL PARCO", value: "anzano-del-parco" },
    { label: "IQOS PREMIUM PARTNER AOSTA", value: "aosta" },
    { label: "IQOS PREMIUM PARTNER APRICA", value: "aprica" },
    { label: "IQOS PREMIUM PARTNER APRILIA", value: "aprilia" },
    { label: "IQOS PREMIUM PARTNER ARAGONA", value: "aragona" },
    { label: "IQOS PREMIUM PARTNER ARCHE SCALIGERE", value: "arche-scaligere" },
    { label: "IQOS PREMIUM PARTNER ARCOLA", value: "arcola" },
    { label: "IQOS PREMIUM PARTNER ARCOLA", value: "arcola" },
    { label: "IQOS PREMIUM PARTNER ARCORE", value: "arcore" },
    { label: "IQOS PREMIUM PARTNER AREZZO", value: "arezzo" },
    { label: "IQOS PREMIUM PARTNER ARIANO IRPINO", value: "ariano-irpino" },
    { label: "IQOS PREMIUM PARTNER ARIENZO", value: "arienzo" },
    { label: "IQOS PREMIUM PARTNER ARMA DI TAGGIA", value: "arma-di-taggia" },
    { label: "IQOS PREMIUM PARTNER ARONA", value: "arona" },
    { label: "IQOS PREMIUM PARTNER ARZACHENA", value: "arzachena" },
    { label: "IQOS PREMIUM PARTNER ARZANO", value: "arzano" },
    { label: "IQOS PREMIUM PARTNER ARZIGNANO", value: "arzignano" },
    { label: "IQOS PREMIUM PARTNER ASCOLI PICENO", value: "ascoli-piceno" },
    { label: "IQOS PREMIUM PARTNER ASSAGO", value: "assago" },
    { label: "IQOS PREMIUM PARTNER ASTI", value: "asti" },
    { label: "IQOS PREMIUM PARTNER ATESSA", value: "atessa" },
    { label: "IQOS PREMIUM PARTNER ATRIPALDA", value: "atripalda" },
    { label: "IQOS PREMIUM PARTNER AUGUSTA", value: "augusta" },
    { label: "IQOS PREMIUM PARTNER AULLA", value: "aulla" },
    { label: "IQOS PREMIUM PARTNER AURONZO DI CADORE", value: "auronzo-di-cadore" },
    { label: "IQOS PREMIUM PARTNER AURORA", value: "aurora" },
    { label: "IQOS PREMIUM PARTNER AVELLINO", value: "avellino" },
    { label: "IQOS PREMIUM PARTNER AVERSA", value: "aversa" },
    { label: "IQOS PREMIUM PARTNER AVEZZANO", value: "avezzano" },
    { label: "IQOS PREMIUM PARTNER AVOLA", value: "avola" },
    { label: "IQOS PREMIUM PARTNER AZZANO DECIMO", value: "azzano-decimo" },
    { label: "IQOS PREMIUM PARTNER BACOLI", value: "bacoli" },
    { label: "IQOS PREMIUM PARTNER BAGHERIA", value: "bagheria" },
    { label: "IQOS PREMIUM PARTNER BAGHERIA STADIO", value: "bagheria-stadio" },
    { label: "IQOS PREMIUM PARTNER BAGNO A RIPOLI", value: "bagno-a-ripoli" },
    { label: "IQOS PREMIUM PARTNER BAGNOLI", value: "bagnoli" },
    { label: "IQOS PREMIUM PARTNER BAIAMONTI", value: "baiamonti" },
    { label: "IQOS PREMIUM PARTNER BALDO DEGLI UBALDI", value: "baldo-degli-ubaldi" },
    { label: "IQOS PREMIUM PARTNER BALLARO'", value: "ballaro'" },
    { label: "IQOS PREMIUM PARTNER BARCELLONA POZZO DI GOTTO", value: "barcellona-pozzo-di-gotto" },
    { label: "IQOS PREMIUM PARTNER BARI", value: "bari" },
    { label: "IQOS PREMIUM PARTNER BARLETTA", value: "barletta" },
    { label: "IQOS PREMIUM PARTNER BARLETTA BARBERINI", value: "barletta-barberini" },
    { label: "IQOS PREMIUM PARTNER BARRA", value: "barra" },
    { label: "IQOS PREMIUM PARTNER BASTIA UMBRA", value: "bastia-umbra" },
    { label: "IQOS PREMIUM PARTNER BATTINDARNO", value: "battindarno" },
    { label: "IQOS PREMIUM PARTNER BATTIPAGLIA", value: "battipaglia" },
    { label: "IQOS PREMIUM PARTNER BECCARIA", value: "beccaria" },
    { label: "IQOS PREMIUM PARTNER BELGIOIOSO", value: "belgioioso" },
    { label: "IQOS PREMIUM PARTNER BELLARIA", value: "bellaria" },
    { label: "IQOS PREMIUM PARTNER BELVEDERE MARITTIMO", value: "belvedere-marittimo" },
    { label: "IQOS PREMIUM PARTNER BENEVENTO", value: "benevento" },
    { label: "IQOS PREMIUM PARTNER BERGAMO", value: "bergamo" },
    { label: "IQOS PREMIUM PARTNER BERNALDA", value: "bernalda" },
    { label: "IQOS PREMIUM PARTNER BIANCAVILLA", value: "biancavilla" },
    { label: "IQOS PREMIUM PARTNER BIELLA", value: "biella" },
    { label: "IQOS PREMIUM PARTNER BISACQUINO", value: "bisacquino" },
    { label: "IQOS PREMIUM PARTNER BISCEGLIE", value: "bisceglie" },
    { label: "IQOS PREMIUM PARTNER BITONTO", value: "bitonto" },
    { label: "IQOS PREMIUM PARTNER BOLOGNA CENTRO", value: "bologna-centro" },
    { label: "IQOS PREMIUM PARTNER BOLZANETO", value: "bolzaneto" },
    { label: "IQOS PREMIUM PARTNER BOLZANO OLTRISARCO", value: "bolzano-oltrisarco" },
    { label: "IQOS PREMIUM PARTNER BONDENO", value: "bondeno" },
    { label: "IQOS PREMIUM PARTNER BORDIGHERA", value: "bordighera" },
    { label: "IQOS PREMIUM PARTNER BORGO ROMA", value: "borgo-roma" },
    { label: "IQOS PREMIUM PARTNER BORGO S. LORENZO", value: "borgo-s.-lorenzo" },
    { label: "IQOS PREMIUM PARTNER BORGO VAL DI TARO", value: "borgo-val-di-taro" },
    { label: "IQOS PREMIUM PARTNER BORGO VITTORIA", value: "borgo-vittoria" },
    { label: "IQOS PREMIUM PARTNER BORGOMANERO", value: "borgomanero" },
    { label: "IQOS PREMIUM PARTNER BORGOSESIA", value: "borgosesia" },
    { label: "IQOS PREMIUM PARTNER BOVOLONE", value: "bovolone" },
    { label: "IQOS PREMIUM PARTNER BOZEN CENTRUM", value: "bozen-centrum" },
    { label: "IQOS PREMIUM PARTNER BRA", value: "bra" },
    { label: "IQOS PREMIUM PARTNER BRESCIA EST", value: "brescia-est" },
    { label: "IQOS PREMIUM PARTNER BRESCIA OVEST", value: "brescia-ovest" },
    { label: "IQOS PREMIUM PARTNER BRESSANONE", value: "bressanone" },
    { label: "IQOS PREMIUM PARTNER BRINDISI", value: "brindisi" },
    { label: "IQOS PREMIUM PARTNER BRINDISI CENTRO", value: "brindisi-centro" },
    { label: "IQOS PREMIUM PARTNER BRONI", value: "broni" },
    { label: "IQOS PREMIUM PARTNER BRONTE", value: "bronte" },
    { label: "IQOS PREMIUM PARTNER BRUGHERIO", value: "brugherio" },
    { label: "IQOS PREMIUM PARTNER BRUNICO", value: "brunico" },
    { label: "IQOS PREMIUM PARTNER BRUZZANO", value: "bruzzano" },
    { label: "IQOS PREMIUM PARTNER BUCCINASCO", value: "buccinasco" },
    { label: "IQOS PREMIUM PARTNER BUDRIO", value: "budrio" },
    { label: "IQOS PREMIUM PARTNER BUFALOTTA", value: "bufalotta" },
    { label: "IQOS PREMIUM PARTNER BUSSOLENGO", value: "bussolengo" },
    { label: "IQOS PREMIUM PARTNER BUSTO ARSIZIO", value: "busto-arsizio" },
    { label: "IQOS PREMIUM PARTNER CABRAS", value: "cabras" },
    { label: "IQOS PREMIUM PARTNER CACCAMO", value: "caccamo" },
    { label: "IQOS PREMIUM PARTNER CADORNA", value: "cadorna" },
    { label: "IQOS PREMIUM PARTNER CAGLIARI", value: "cagliari" },
    { label: "IQOS PREMIUM PARTNER CAGLIARI PIRRI", value: "cagliari-pirri" },
    { label: "IQOS PREMIUM PARTNER CALIMERA", value: "calimera" },
    { label: "IQOS PREMIUM PARTNER CALMAGGIORE", value: "calmaggiore" },
    { label: "IQOS PREMIUM PARTNER CALTAGIRONE", value: "caltagirone" },
    { label: "IQOS PREMIUM PARTNER CALTANISSETTA", value: "caltanissetta" },
    { label: "IQOS PREMIUM PARTNER CALUSCO D'ADDA", value: "calusco-d'adda" },
    { label: "IQOS PREMIUM PARTNER CAMERA DEI DEPUTATI", value: "camera-dei-deputati" },
    { label: "IQOS PREMIUM PARTNER CAMPAGNA", value: "campagna" },
    { label: "IQOS PREMIUM PARTNER CAMPOBASSO", value: "campobasso" },
    { label: "IQOS PREMIUM PARTNER CAMPOBASSO EST", value: "campobasso-est" },
    { label: "IQOS PREMIUM PARTNER CAMPOFORMIDO", value: "campoformido" },
    { label: "IQOS PREMIUM PARTNER CAMPONOGARA", value: "camponogara" },
    { label: "IQOS PREMIUM PARTNER CAMPORA S.GIOVANNI", value: "campora-s.giovanni" },
    { label: "IQOS PREMIUM PARTNER CAMPOSAMPIERO", value: "camposampiero" },
    { label: "IQOS PREMIUM PARTNER CANICATTI'", value: "canicatti'" },
    { label: "IQOS PREMIUM PARTNER CANOSA DI PUGLIA", value: "canosa-di-puglia" },
    { label: "IQOS PREMIUM PARTNER CANTU'", value: "cantu'" },
    { label: "IQOS PREMIUM PARTNER CAPACCIO PAESTUM", value: "capaccio-paestum" },
    { label: "IQOS PREMIUM PARTNER CAPO D'ORLANDO", value: "capo-d'orlando" },
    { label: "IQOS PREMIUM PARTNER CAPPUCCINI", value: "cappuccini" },
    { label: "IQOS PREMIUM PARTNER CAPUA", value: "capua" },
    { label: "IQOS PREMIUM PARTNER CAPURSO", value: "capurso" },
    { label: "IQOS PREMIUM PARTNER CARBONIA", value: "carbonia" },
    { label: "IQOS PREMIUM PARTNER CARDANO AL CAMPO", value: "cardano-al-campo" },
    { label: "IQOS PREMIUM PARTNER CARINI", value: "carini" },
    { label: "IQOS PREMIUM PARTNER CARLOFORTE", value: "carloforte" },
    { label: "IQOS PREMIUM PARTNER CARMAGNOLA", value: "carmagnola" },
    { label: "IQOS PREMIUM PARTNER CARPI", value: "carpi" },
    { label: "IQOS PREMIUM PARTNER CARRARA", value: "carrara" },
    { label: "IQOS PREMIUM PARTNER CASAGIOVE", value: "casagiove" },
    { label: "IQOS PREMIUM PARTNER CASAL DI PRINCIPE", value: "casal-di-principe" },
    { label: "IQOS PREMIUM PARTNER CASAL LUMBROSO", value: "casal-lumbroso" },
    { label: "IQOS PREMIUM PARTNER CASALE MONFERRATO", value: "casale-monferrato" },
    { label: "IQOS PREMIUM PARTNER CASALNUOVO DI NAPOLI", value: "casalnuovo-di-napoli" },
    { label: "IQOS PREMIUM PARTNER CASARANO", value: "casarano" },
    { label: "IQOS PREMIUM PARTNER CASATENOVO", value: "casatenovo" },
    { label: "IQOS PREMIUM PARTNER CASAVATORE", value: "casavatore" },
    { label: "IQOS PREMIUM PARTNER CASCINA", value: "cascina" },
    { label: "IQOS PREMIUM PARTNER CASERTA", value: "caserta" },
    { label: "IQOS PREMIUM PARTNER CASERTA CENTRO", value: "caserta-centro" },
    { label: "IQOS PREMIUM PARTNER CASORATE PRIMO", value: "casorate-primo" },
    { label: "IQOS PREMIUM PARTNER CASORIA", value: "casoria" },
    { label: "IQOS PREMIUM PARTNER CASSANO D'ADDA", value: "cassano-d'adda" },
    { label: "IQOS PREMIUM PARTNER CASSANO DELLE MURGE", value: "cassano-delle-murge" },
    { label: "IQOS PREMIUM PARTNER CASSINO", value: "cassino" },
    { label: "IQOS PREMIUM PARTNER CASTANO PRIMO", value: "castano-primo" },
    { label: "IQOS PREMIUM PARTNER CASTEL DI SANGRO", value: "castel-di-sangro" },
    { label: "IQOS PREMIUM PARTNER CASTEL MAGGIORE", value: "castel-maggiore" },
    { label: "IQOS PREMIUM PARTNER CASTEL NUOVO DI PORTO", value: "castel-nuovo-di-porto" },
    { label: "IQOS PREMIUM PARTNER CASTEL S.GIOVANNI", value: "castel-s.giovanni" },
    { label: "IQOS PREMIUM PARTNER CASTEL VOLTURNO", value: "castel-volturno" },
    { label: "IQOS PREMIUM PARTNER CASTELBUONO", value: "castelbuono" },
    { label: "IQOS PREMIUM PARTNER CASTELFIORENTINO", value: "castelfiorentino" },
    { label: "IQOS PREMIUM PARTNER CASTELFRANCO VENETO", value: "castelfranco-veneto" },
    { label: "IQOS PREMIUM PARTNER CASTELGOMBERTO", value: "castelgomberto" },
    { label: "IQOS PREMIUM PARTNER CASTELLAMMARE DEL GOLFO", value: "castellammare-del-golfo" },
    { label: "IQOS PREMIUM PARTNER CASTELLAMMARE DI STABIA", value: "castellammare-di-stabia" },
    { label: "IQOS PREMIUM PARTNER CASTELLANETA", value: "castellaneta" },
    { label: "IQOS PREMIUM PARTNER CASTELLANZA", value: "castellanza" },
    { label: "IQOS PREMIUM PARTNER CASTELLEONE", value: "castelleone" },
    { label: "IQOS PREMIUM PARTNER CASTELNOVO NE' MONTI", value: "castelnovo-ne'-monti" },
    { label: "IQOS PREMIUM PARTNER CASTELNUOVO DI GARFAGNANA", value: "castelnuovo-di-garfagnana" },
    { label: "IQOS PREMIUM PARTNER CASTELNUOVO DON BOSCO", value: "castelnuovo-don-bosco" },
    { label: "IQOS PREMIUM PARTNER CASTELSARDO", value: "castelsardo" },
    { label: "IQOS PREMIUM PARTNER CASTELTERMINI", value: "casteltermini" },
    { label: "IQOS PREMIUM PARTNER CASTELVETRANO", value: "castelvetrano" },
    { label: "IQOS PREMIUM PARTNER CASTENASO", value: "castenaso" },
    { label: "IQOS PREMIUM PARTNER CASTIGLION FIORENTINO", value: "castiglion-fiorentino" },
    { label: "IQOS PREMIUM PARTNER CASTIGLIONE DELLA PESCAIA", value: "castiglione-della-pescaia" },
    { label: "IQOS PREMIUM PARTNER CASTIGLIONE DELLE STIVIERE", value: "castiglione-delle-stiviere" },
    { label: "IQOS PREMIUM PARTNER CASTROVILLARI", value: "castrovillari" },
    { label: "IQOS PREMIUM PARTNER CATANIA VIA ETNEA", value: "catania-via-etnea" },
    { label: "IQOS PREMIUM PARTNER CATANZARO", value: "catanzaro" },
    { label: "IQOS PREMIUM PARTNER CATANZARO LIDO", value: "catanzaro-lido" },
    { label: "IQOS PREMIUM PARTNER CATTOLICA", value: "cattolica" },
    { label: "IQOS PREMIUM PARTNER CAVA DE' TIRRENI", value: "cava-de'-tirreni" },
    { label: "IQOS PREMIUM PARTNER CAVALESE", value: "cavalese" },
    { label: "IQOS PREMIUM PARTNER CAVARZERE", value: "cavarzere" },
    { label: "IQOS PREMIUM PARTNER CECCANO", value: "ceccano" },
    { label: "IQOS PREMIUM PARTNER CECINA", value: "cecina" },
    { label: "IQOS PREMIUM PARTNER CEFALU'", value: "cefalu'" },
    { label: "IQOS PREMIUM PARTNER CENTO ", value: "cento" },
    { label: "IQOS PREMIUM PARTNER CENTOCELLE", value: "centocelle" },
    { label: "IQOS PREMIUM PARTNER CENTOLA", value: "centola" },
    { label: "IQOS PREMIUM PARTNER CEPRANO", value: "ceprano" },
    { label: "IQOS PREMIUM PARTNER CERIGNOLA", value: "cerignola" },
    { label: "IQOS PREMIUM PARTNER CERNOBBIO", value: "cernobbio" },
    { label: "IQOS PREMIUM PARTNER CERVETERI", value: "cerveteri" },
    { label: "IQOS PREMIUM PARTNER CERVIA", value: "cervia" },
    { label: "IQOS PREMIUM PARTNER CESENA", value: "cesena" },
    { label: "IQOS PREMIUM PARTNER CESENATICO", value: "cesenatico" },
    { label: "IQOS PREMIUM PARTNER CHIARI", value: "chiari" },
    { label: "IQOS PREMIUM PARTNER CHIAVARI", value: "chiavari" },
    { label: "IQOS PREMIUM PARTNER CHIAVENNA", value: "chiavenna" },
    { label: "IQOS PREMIUM PARTNER CHIERI", value: "chieri" },
    { label: "IQOS PREMIUM PARTNER CHIETI SCALO", value: "chieti-scalo" },
    { label: "IQOS PREMIUM PARTNER CHIOGGIA", value: "chioggia" },
    { label: "IQOS PREMIUM PARTNER CHIVASSO", value: "chivasso" },
    { label: "IQOS PREMIUM PARTNER CIAMPINO", value: "ciampino" },
    { label: "IQOS PREMIUM PARTNER CICCIANO", value: "cicciano" },
    { label: "IQOS PREMIUM PARTNER CINECITTA'", value: "cinecitta'" },
    { label: "IQOS PREMIUM PARTNER CINISELLO BALSAMO", value: "cinisello-balsamo" },
    { label: "IQOS PREMIUM PARTNER CIRIE'", value: "cirie'" },
    { label: "IQOS PREMIUM PARTNER CIRO' MARINA", value: "ciro'-marina" },
    { label: "IQOS PREMIUM PARTNER CISTERNA DI LATINA", value: "cisterna-di-latina" },
    { label: "IQOS PREMIUM PARTNER CIT TURIN", value: "cit-turin" },
    { label: "IQOS PREMIUM PARTNER CIVITA CASTELLANA", value: "civita-castellana" },
    { label: "IQOS PREMIUM PARTNER CIVITANOVA MARCHE", value: "civitanova-marche" },
    { label: "IQOS PREMIUM PARTNER CIVITAVECCHIA", value: "civitavecchia" },
    { label: "IQOS PREMIUM PARTNER CIVITAVECCHIA NORD", value: "civitavecchia-nord" },
    { label: "IQOS PREMIUM PARTNER CLES", value: "cles" },
    { label: "IQOS PREMIUM PARTNER CLUSONE", value: "clusone" },
    { label: "IQOS PREMIUM PARTNER CODOGNO", value: "codogno" },
    { label: "IQOS PREMIUM PARTNER CODROIPO", value: "codroipo" },
    { label: "IQOS PREMIUM PARTNER COLA DI RIENZO", value: "cola-di-rienzo" },
    { label: "IQOS PREMIUM PARTNER COLLE DI VAL D'ELSA", value: "colle-di-val-d'elsa" },
    { label: "IQOS PREMIUM PARTNER COLLEFERRO", value: "colleferro" },
    { label: "IQOS PREMIUM PARTNER COLLEGNO", value: "collegno" },
    { label: "IQOS PREMIUM PARTNER COLLESALVETTI", value: "collesalvetti" },
    { label: "IQOS PREMIUM PARTNER COLLI ANIENE", value: "colli-aniene" },
    { label: "IQOS PREMIUM PARTNER COLOGNO MONZESE", value: "cologno-monzese" },
    { label: "IQOS PREMIUM PARTNER COMACCHIO", value: "comacchio" },
    { label: "IQOS PREMIUM PARTNER COMISO", value: "comiso" },
    { label: "IQOS PREMIUM PARTNER COMO", value: "como" },
    { label: "IQOS PREMIUM PARTNER CONVERSANO", value: "conversano" },
    { label: "IQOS PREMIUM PARTNER CORATO", value: "corato" },
    { label: "IQOS PREMIUM PARTNER CORCIANO", value: "corciano" },
    { label: "IQOS PREMIUM PARTNER CORCOLLE", value: "corcolle" },
    { label: "IQOS PREMIUM PARTNER CORIGLIANO ROSSANO", value: "corigliano-rossano" },
    { label: "IQOS PREMIUM PARTNER CORSO AMENDOLA", value: "corso-amendola" },
    { label: "IQOS PREMIUM PARTNER CORSO CASALE", value: "corso-casale" },
    { label: "IQOS PREMIUM PARTNER CORSO FRANCIA", value: "corso-francia" },
    { label: "IQOS PREMIUM PARTNER CORSO GENOVA", value: "corso-genova" },
    { label: "IQOS PREMIUM PARTNER CORSO GIULIO CESARE", value: "corso-giulio-cesare" },
    { label: "IQOS PREMIUM PARTNER CORSO INDIPENDENZA", value: "corso-indipendenza" },
    { label: "IQOS PREMIUM PARTNER CORSO LODI", value: "corso-lodi" },
    { label: "IQOS PREMIUM PARTNER CORSO PESCHIERA", value: "corso-peschiera" },
    { label: "IQOS PREMIUM PARTNER CORSO REGINA", value: "corso-regina" },
    { label: "IQOS PREMIUM PARTNER CORSO TELESIO", value: "corso-telesio" },
    { label: "IQOS PREMIUM PARTNER Corso Torino", value: "corso-torino" },
    { label: "IQOS PREMIUM PARTNER CORSO XXII MARZO", value: "corso-xxii-marzo" },
    { label: "IQOS PREMIUM PARTNER CORTICELLA", value: "corticella" },
    { label: "IQOS PREMIUM PARTNER CORTONA", value: "cortona" },
    { label: "IQOS PREMIUM PARTNER CORVARA IN BADIA", value: "corvara-in-badia" },
    { label: "IQOS PREMIUM PARTNER COSENZA", value: "cosenza" },
    { label: "IQOS PREMIUM PARTNER COSSATO", value: "cossato" },
    { label: "IQOS PREMIUM PARTNER COSTA VOLPINO", value: "costa-volpino" },
    { label: "IQOS PREMIUM PARTNER CREMA", value: "crema" },
    { label: "IQOS PREMIUM PARTNER CREMONA", value: "cremona" },
    { label: "IQOS PREMIUM PARTNER CRISPIANO", value: "crispiano" },
    { label: "IQOS PREMIUM PARTNER CROCETTA", value: "crocetta" },
    { label: "IQOS PREMIUM PARTNER CROTONE", value: "crotone" },
    { label: "IQOS PREMIUM PARTNER CUNEO", value: "cuneo" },
    { label: "IQOS PREMIUM PARTNER CUPRA MARITTIMA", value: "cupra-marittima" },
    { label: "IQOS PREMIUM PARTNER CURA CARPIGNANO", value: "cura-carpignano" },
    { label: "IQOS PREMIUM PARTNER CUSANO MILANINO", value: "cusano-milanino" },
    { label: "IQOS PREMIUM PARTNER DESENZANO DEL GARDA", value: "desenzano-del-garda" },
    { label: "IQOS PREMIUM PARTNER DIGNANO", value: "dignano" },
    { label: "IQOS PREMIUM PARTNER DOMODOSSOLA", value: "domodossola" },
    { label: "IQOS PREMIUM PARTNER DON RODRIGO", value: "don-rodrigo" },
    { label: "IQOS PREMIUM PARTNER DUEVILLE", value: "dueville" },
    { label: "IQOS PREMIUM PARTNER DUOMO", value: "duomo" },
    { label: "IQOS PREMIUM PARTNER EDOLO", value: "edolo" },
    { label: "IQOS PREMIUM PARTNER EGNA", value: "egna" },
    { label: "IQOS PREMIUM PARTNER EMPOLI", value: "empoli" },
    { label: "IQOS PREMIUM PARTNER ENNA", value: "enna" },
    { label: "IQOS PREMIUM PARTNER ERBA", value: "erba" },
    { label: "IQOS PREMIUM PARTNER ERCOLANO", value: "ercolano" },
    { label: "IQOS PREMIUM PARTNER ERICE", value: "erice" },
    { label: "IQOS PREMIUM PARTNER ESTE", value: "este" },
    { label: "IQOS PREMIUM PARTNER EUR - P. DI DONO", value: "eur---p.-di-dono" },
    { label: "IQOS PREMIUM PARTNER EUROSIA", value: "eurosia" },
    { label: "IQOS PREMIUM PARTNER FABRIANO", value: "fabriano" },
    { label: "IQOS PREMIUM PARTNER FAENZA", value: "faenza" },
    { label: "IQOS PREMIUM PARTNER FAENZA SAN MARCO", value: "faenza-san-marco" },
    { label: "IQOS PREMIUM PARTNER FANO", value: "fano" },
    { label: "IQOS PREMIUM PARTNER FANO MAROTTA", value: "fano-marotta" },
    { label: "IQOS PREMIUM PARTNER FASANO", value: "fasano" },
    { label: "IQOS PREMIUM PARTNER FAVARA", value: "favara" },
    { label: "IQOS PREMIUM PARTNER FELTRE", value: "feltre" },
    { label: "IQOS PREMIUM PARTNER FERENTINO", value: "ferentino" },
    { label: "IQOS PREMIUM PARTNER FERMIGNANO", value: "fermignano" },
    { label: "IQOS PREMIUM PARTNER FERMO", value: "fermo" },
    { label: "IQOS PREMIUM PARTNER FERRARA CENTRO", value: "ferrara-centro" },
    { label: "IQOS PREMIUM PARTNER FICARAZZI", value: "ficarazzi" },
    { label: "IQOS PREMIUM PARTNER FIDENZA", value: "fidenza" },
    { label: "IQOS PREMIUM PARTNER FIGLINE E INCISA VALDARNO", value: "figline-e-incisa-valdarno" },
    { label: "IQOS PREMIUM PARTNER FINALE LIGURE", value: "finale-ligure" },
    { label: "IQOS PREMIUM PARTNER FIORANO MODENESE", value: "fiorano-modenese" },
    { label: "IQOS PREMIUM PARTNER FIORENZUOLA D'ARDA", value: "fiorenzuola-d'arda" },
    { label: "IQOS PREMIUM PARTNER FIRENZE", value: "firenze" },
    { label: "IQOS PREMIUM PARTNER FIRENZE CAMPO DI MARTE", value: "firenze-campo-di-marte" },
    { label: "IQOS PREMIUM PARTNER FIRENZE CAREGGI", value: "firenze-careggi" },
    { label: "IQOS PREMIUM PARTNER FIRENZE DUOMO", value: "firenze-duomo" },
    { label: "IQOS PREMIUM PARTNER FIRENZE ISOLOTTO", value: "firenze-isolotto" },
    { label: "IQOS PREMIUM PARTNER FIRENZE SUD", value: "firenze-sud" },
    { label: "IQOS PREMIUM PARTNER FIUGGI", value: "fiuggi" },
    { label: "IQOS PREMIUM PARTNER FIUMICINO", value: "fiumicino" },
    { label: "IQOS PREMIUM PARTNER FIUMICINO CENTRO", value: "fiumicino-centro" },
    { label: "IQOS PREMIUM PARTNER FLEMING", value: "fleming" },
    { label: "IQOS PREMIUM PARTNER FLERO", value: "flero" },
    { label: "IQOS PREMIUM PARTNER FLORIDIA", value: "floridia" },
    { label: "IQOS PREMIUM PARTNER FOGGIA", value: "foggia" },
    { label: "IQOS PREMIUM PARTNER FOGGIA CENTRO", value: "foggia-centro" },
    { label: "IQOS PREMIUM PARTNER FOGGIA GARIBALDI", value: "foggia-garibaldi" },
    { label: "IQOS PREMIUM PARTNER FOLIGNO", value: "foligno" },
    { label: "IQOS PREMIUM PARTNER FOLLONICA", value: "follonica" },
    { label: "IQOS PREMIUM PARTNER FONDI", value: "fondi" },
    { label: "IQOS PREMIUM PARTNER FONTE NUOVA", value: "fonte-nuova" },
    { label: "IQOS PREMIUM PARTNER FONTE NUOVA SANTA LUCIA", value: "fonte-nuova-santa-lucia" },
    { label: "IQOS PREMIUM PARTNER FORANO", value: "forano" },
    { label: "IQOS PREMIUM PARTNER FORLI'", value: "forli'" },
    { label: "IQOS PREMIUM PARTNER FORMELLO", value: "formello" },
    { label: "IQOS PREMIUM PARTNER FORMIA", value: "formia" },
    { label: "IQOS PREMIUM PARTNER FORMIGINE", value: "formigine" },
    { label: "IQOS PREMIUM PARTNER FORNOVO DI TARO", value: "fornovo-di-taro" },
    { label: "IQOS PREMIUM PARTNER FOSSANO", value: "fossano" },
    { label: "IQOS PREMIUM PARTNER FRANCAVILLA AL MARE", value: "francavilla-al-mare" },
    { label: "IQOS PREMIUM PARTNER FRANCAVILLA FONTANA", value: "francavilla-fontana" },
    { label: "IQOS PREMIUM PARTNER FRANCOFONTE", value: "francofonte" },
    { label: "IQOS PREMIUM PARTNER FRASCATI", value: "frascati" },
    { label: "IQOS PREMIUM PARTNER FRATTAMAGGIORE", value: "frattamaggiore" },
    { label: "IQOS PREMIUM PARTNER FRIGENTO", value: "frigento" },
    { label: "IQOS PREMIUM PARTNER FROSINONE", value: "frosinone" },
    { label: "IQOS PREMIUM PARTNER FRULLONE", value: "frullone" },
    { label: "IQOS PREMIUM PARTNER FUCECCHIO", value: "fucecchio" },
    { label: "IQOS PREMIUM PARTNER FUORIGROTTA", value: "fuorigrotta" },
    { label: "IQOS PREMIUM PARTNER GAETA", value: "gaeta" },
    { label: "IQOS PREMIUM PARTNER GALATINA", value: "galatina" },
    { label: "IQOS PREMIUM PARTNER GALLARATE", value: "gallarate" },
    { label: "IQOS PREMIUM PARTNER GALLIATE", value: "galliate" },
    { label: "IQOS PREMIUM PARTNER GALLIPOLI", value: "gallipoli" },
    { label: "IQOS PREMIUM PARTNER GARBATELLA", value: "garbatella" },
    { label: "IQOS PREMIUM PARTNER GELA", value: "gela" },
    { label: "IQOS PREMIUM PARTNER GENOVA CORNIGLIANO", value: "genova-cornigliano" },
    { label: "IQOS PREMIUM PARTNER GENOVA GARIGNANO", value: "genova-garignano" },
    { label: "IQOS PREMIUM PARTNER GENOVA LEVANTE", value: "genova-levante" },
    { label: "IQOS PREMIUM PARTNER GENOVA MARTINETTI", value: "genova-martinetti" },
    { label: "IQOS PREMIUM PARTNER GENOVA NERVI", value: "genova-nervi" },
    { label: "IQOS PREMIUM PARTNER GENOVA PEGLI", value: "genova-pegli" },
    { label: "IQOS PREMIUM PARTNER GENZANO DI ROMA", value: "genzano-di-roma" },
    { label: "IQOS PREMIUM PARTNER GIANO DELL'UMBRIA", value: "giano-dell'umbria" },
    { label: "IQOS PREMIUM PARTNER GIOIA DEL COLLE", value: "gioia-del-colle" },
    { label: "IQOS PREMIUM PARTNER GIOIA TAURO", value: "gioia-tauro" },
    { label: "IQOS PREMIUM PARTNER GIOVINAZZO", value: "giovinazzo" },
    { label: "IQOS PREMIUM PARTNER GIUGLIANO IN CAMPANIA", value: "giugliano-in-campania" },
    { label: "IQOS PREMIUM PARTNER GIULIANOVA", value: "giulianova" },
    { label: "IQOS PREMIUM PARTNER GIUSSANO", value: "giussano" },
    { label: "IQOS PREMIUM PARTNER GORIZIA", value: "gorizia" },
    { label: "IQOS PREMIUM PARTNER GRANDATE", value: "grandate" },
    { label: "IQOS PREMIUM PARTNER GRAVELLONA TOCE", value: "gravellona-toce" },
    { label: "IQOS PREMIUM PARTNER GRAVINA DI CATANIA", value: "gravina-di-catania" },
    { label: "IQOS PREMIUM PARTNER GREZZANA", value: "grezzana" },
    { label: "IQOS PREMIUM PARTNER GROTTAGLIE", value: "grottaglie" },
    { label: "IQOS PREMIUM PARTNER GRUMO NEVANO", value: "grumo-nevano" },
    { label: "IQOS PREMIUM PARTNER GUBBIO", value: "gubbio" },
    { label: "IQOS PREMIUM PARTNER GUIDO RENI", value: "guido-reni" },
    { label: "IQOS PREMIUM PARTNER GUIDONIA MONTECELIO", value: "guidonia-montecelio" },
    { label: "IQOS PREMIUM PARTNER IGLESIAS", value: "iglesias" },
    { label: "IQOS PREMIUM PARTNER IL RINGO", value: "il-ringo" },
    { label: "IQOS PREMIUM PARTNER IMOLA", value: "imola" },
    { label: "IQOS PREMIUM PARTNER IMPERIA", value: "imperia" },
    { label: "IQOS PREMIUM PARTNER ISCHIA", value: "ischia" },
    { label: "IQOS PREMIUM PARTNER ISERNIA", value: "isernia" },
    { label: "IQOS PREMIUM PARTNER ISOLA", value: "isola" },
    { label: "IQOS PREMIUM PARTNER ISOLA DI CAPO RIZZUTO", value: "isola-di-capo-rizzuto" },
    { label: "IQOS PREMIUM PARTNER ISPICA", value: "ispica" },
    { label: "IQOS PREMIUM PARTNER IVREA", value: "ivrea" },
    { label: "IQOS PREMIUM PARTNER JESI", value: "jesi" },
    { label: "IQOS PREMIUM PARTNER JESOLO", value: "jesolo" },
    { label: "IQOS PREMIUM PARTNER JOLANDA DI SAVOIA", value: "jolanda-di-savoia" },
    { label: "IQOS PREMIUM PARTNER L'AQUILA", value: "l'aquila" },
    { label: "IQOS PREMIUM PARTNER L'AQUILA CENTRO", value: "l'aquila-centro" },
    { label: "IQOS PREMIUM PARTNER L'AQUILA EST", value: "l'aquila-est" },
    { label: "IQOS PREMIUM PARTNER LA GIUSTINIANA", value: "la-giustiniana" },
    { label: "IQOS PREMIUM PARTNER LA LOGGIA", value: "la-loggia" },
    { label: "IQOS PREMIUM PARTNER LA MADDALENA", value: "la-maddalena" },
    { label: "IQOS PREMIUM PARTNER LA SPEZIA", value: "la-spezia" },
    { label: "IQOS PREMIUM PARTNER LADISPOLI", value: "ladispoli" },
    { label: "IQOS PREMIUM PARTNER LAGONEGRO", value: "lagonegro" },
    { label: "IQOS PREMIUM PARTNER LAGUNDO", value: "lagundo" },
    { label: "IQOS PREMIUM PARTNER LAINATE", value: "lainate" },
    { label: "IQOS PREMIUM PARTNER LAIVES", value: "laives" },
    { label: "IQOS PREMIUM PARTNER LAMBRATE", value: "lambrate" },
    { label: "IQOS PREMIUM PARTNER LAMEZIA TERME", value: "lamezia-terme" },
    { label: "IQOS PREMIUM PARTNER LANCIANO", value: "lanciano" },
    { label: "IQOS PREMIUM PARTNER LANUSEI", value: "lanusei" },
    { label: "IQOS PREMIUM PARTNER LARGO LA FOPPA", value: "largo-la-foppa" },
    { label: "IQOS PREMIUM PARTNER LARGO S.GIOVANNI", value: "largo-s.giovanni" },
    { label: "IQOS PREMIUM PARTNER LARIANO", value: "lariano" },
    { label: "IQOS PREMIUM PARTNER LASTRA A SIGNA", value: "lastra-a-signa" },
    { label: "IQOS PREMIUM PARTNER LATINA", value: "latina" },
    { label: "IQOS PREMIUM PARTNER LATINA", value: "latina" },
    { label: "IQOS PREMIUM PARTNER LATISANA", value: "latisana" },
    { label: "IQOS PREMIUM PARTNER LAVAGNO", value: "lavagno" },
    { label: "IQOS PREMIUM PARTNER LAVELLO", value: "lavello" },
    { label: "IQOS PREMIUM PARTNER LE ZAGARE", value: "le-zagare" },
    { label: "IQOS PREMIUM PARTNER LECCE", value: "lecce" },
    { label: "IQOS PREMIUM PARTNER LECCE JAPIGIA", value: "lecce-japigia" },
    { label: "IQOS PREMIUM PARTNER LECCO", value: "lecco" },
    { label: "IQOS PREMIUM PARTNER LEGNAGO", value: "legnago" },
    { label: "IQOS PREMIUM PARTNER LENDINARA", value: "lendinara" },
    { label: "IQOS PREMIUM PARTNER LEPORANO", value: "leporano" },
    { label: "IQOS PREMIUM PARTNER LICATA", value: "licata" },
    { label: "IQOS PREMIUM PARTNER LIDO DI CAMAIORE", value: "lido-di-camaiore" },
    { label: "IQOS PREMIUM PARTNER LIDO DI VENEZIA", value: "lido-di-venezia" },
    { label: "IQOS PREMIUM PARTNER LIGNANO SABBIADORO", value: "lignano-sabbiadoro" },
    { label: "IQOS PREMIUM PARTNER LIMANA", value: "limana" },
    { label: "IQOS PREMIUM PARTNER LIMBIATE", value: "limbiate" },
    { label: "IQOS PREMIUM PARTNER LIMENA", value: "limena" },
    { label: "IQOS PREMIUM PARTNER LINGOTTO", value: "lingotto" },
    { label: "IQOS PREMIUM PARTNER LIONI", value: "lioni" },
    { label: "IQOS PREMIUM PARTNER LIPARI", value: "lipari" },
    { label: "IQOS PREMIUM PARTNER LISSONE", value: "lissone" },
    { label: "IQOS PREMIUM PARTNER LIVORNO", value: "livorno" },
    { label: "IQOS PREMIUM PARTNER LOMAGNA", value: "lomagna" },
    { label: "IQOS PREMIUM PARTNER LONATE POZZOLO", value: "lonate-pozzolo" },
    { label: "IQOS PREMIUM PARTNER LONIGO", value: "lonigo" },
    { label: "IQOS PREMIUM PARTNER LORETO", value: "loreto" },
    { label: "IQOS PREMIUM PARTNER LUCCA", value: "lucca" },
    { label: "IQOS PREMIUM PARTNER LUCCA OVEST", value: "lucca-ovest" },
    { label: "IQOS PREMIUM PARTNER LUCERA", value: "lucera" },
    { label: "IQOS PREMIUM PARTNER LUGO", value: "lugo" },
    { label: "IQOS PREMIUM PARTNER LUINO", value: "luino" },
    { label: "IQOS PREMIUM PARTNER LUMEZZANE", value: "lumezzane" },
    { label: "IQOS PREMIUM PARTNER LUNA E SOLE", value: "luna-e-sole" },
    { label: "IQOS PREMIUM PARTNER LUNGARNO", value: "lungarno" },
    { label: "IQOS PREMIUM PARTNER LUNGARNO", value: "lungarno" },
    { label: "IQOS PREMIUM PARTNER LUNI", value: "luni" },
    { label: "IQOS PREMIUM PARTNER MACERATA", value: "macerata" },
    { label: "IQOS PREMIUM PARTNER MACERATA CAMPANIA", value: "macerata-campania" },
    { label: "IQOS PREMIUM PARTNER MACOMER", value: "macomer" },
    { label: "IQOS PREMIUM PARTNER MADDALONI", value: "maddaloni" },
    { label: "IQOS PREMIUM PARTNER MAGLIANELLA", value: "maglianella" },
    { label: "IQOS PREMIUM PARTNER MAGLIE", value: "maglie" },
    { label: "IQOS PREMIUM PARTNER MAIORI", value: "maiori" },
    { label: "IQOS PREMIUM PARTNER MALCESINE", value: "malcesine" },
    { label: "IQOS PREMIUM PARTNER MALLES VENOSTA", value: "malles-venosta" },
    { label: "IQOS PREMIUM PARTNER MALNATE", value: "malnate" },
    { label: "IQOS PREMIUM PARTNER MANDELLO DEL LARIO", value: "mandello-del-lario" },
    { label: "IQOS PREMIUM PARTNER MANDURIA", value: "manduria" },
    { label: "IQOS PREMIUM PARTNER MANERBIO", value: "manerbio" },
    { label: "IQOS PREMIUM PARTNER MANFREDONIA", value: "manfredonia" },
    { label: "IQOS PREMIUM PARTNER MANTOVA", value: "mantova" },
    { label: "IQOS PREMIUM PARTNER MAPPANO", value: "mappano" },
    { label: "IQOS PREMIUM PARTNER MARACALAGONIS", value: "maracalagonis" },
    { label: "IQOS PREMIUM PARTNER MARANELLO", value: "maranello" },
    { label: "IQOS PREMIUM PARTNER MARANO DI NAPOLI", value: "marano-di-napoli" },
    { label: "IQOS PREMIUM PARTNER MARASSI", value: "marassi" },
    { label: "IQOS PREMIUM PARTNER MARCIANISE", value: "marcianise" },
    { label: "IQOS PREMIUM PARTNER MARCOLOTTO", value: "marcolotto" },
    { label: "IQOS PREMIUM PARTNER MARCON", value: "marcon" },
    { label: "IQOS PREMIUM PARTNER MARIANO COMENSE", value: "mariano-comense" },
    { label: "IQOS PREMIUM PARTNER MARIGLIANO", value: "marigliano" },
    { label: "IQOS PREMIUM PARTNER MARINA DI RAGUSA", value: "marina-di-ragusa" },
    { label: "IQOS PREMIUM PARTNER MARINA DI RAVENNA", value: "marina-di-ravenna" },
    { label: "IQOS PREMIUM PARTNER MARINO", value: "marino" },
    { label: "IQOS PREMIUM PARTNER MARMIROLO", value: "marmirolo" },
    { label: "IQOS PREMIUM PARTNER MARSALA", value: "marsala" },
    { label: "IQOS PREMIUM PARTNER MARTINA FRANCA   ", value: "martina-franca" },
    { label: "IQOS PREMIUM PARTNER MASERADA SUL PIAVE", value: "maserada-sul-piave" },
    { label: "IQOS PREMIUM PARTNER MASSA", value: "massa" },
    { label: "IQOS PREMIUM PARTNER MASSAFRA", value: "massafra" },
    { label: "IQOS PREMIUM PARTNER MASSAROSA", value: "massarosa" },
    { label: "IQOS PREMIUM PARTNER MATERA", value: "matera" },
    { label: "IQOS PREMIUM PARTNER MAZARA DEL VALLO", value: "mazara-del-vallo" },
    { label: "IQOS PREMIUM PARTNER MEDICINA", value: "medicina" },
    { label: "IQOS PREMIUM PARTNER MELENDUGNO", value: "melendugno" },
    { label: "IQOS PREMIUM PARTNER MELITO DI NAPOLI", value: "melito-di-napoli" },
    { label: "IQOS PREMIUM PARTNER MELITO DI PORTO SALVO", value: "melito-di-porto-salvo" },
    { label: "IQOS PREMIUM PARTNER MERCATO S.SEVERINO", value: "mercato-s.severino" },
    { label: "IQOS PREMIUM PARTNER MERIDIANA", value: "meridiana" },
    { label: "IQOS PREMIUM PARTNER MESSINA", value: "messina" },
    { label: "IQOS PREMIUM PARTNER MESTRE CORSO DEL POPOLO", value: "mestre-corso-del-popolo" },
    { label: "IQOS PREMIUM PARTNER MESTRE VIA S.DONÀ", value: "mestre-via-s.donà" },
    { label: "IQOS PREMIUM PARTNER MEZZOCAMMINO", value: "mezzocammino" },
    { label: "IQOS PREMIUM PARTNER MEZZOCANNONE", value: "mezzocannone" },
    { label: "IQOS PREMIUM PARTNER MILAZZO", value: "milazzo" },
    { label: "IQOS PREMIUM PARTNER MIRA", value: "mira" },
    { label: "IQOS PREMIUM PARTNER MIRAFIORI", value: "mirafiori" },
    { label: "IQOS PREMIUM PARTNER MIRAFIORI SUD", value: "mirafiori-sud" },
    { label: "IQOS PREMIUM PARTNER MIRANDOLA", value: "mirandola" },
    { label: "IQOS PREMIUM PARTNER MISSAGLIA", value: "missaglia" },
    { label: "IQOS PREMIUM PARTNER MODENA", value: "modena" },
    { label: "IQOS PREMIUM PARTNER MODICA", value: "modica" },
    { label: "IQOS PREMIUM PARTNER MODUGNO", value: "modugno" },
    { label: "IQOS PREMIUM PARTNER MOENA", value: "moena" },
    { label: "IQOS PREMIUM PARTNER MOGLIANO VENETO", value: "mogliano-veneto" },
    { label: "IQOS PREMIUM PARTNER MOLA DI BARI", value: "mola-di-bari" },
    { label: "IQOS PREMIUM PARTNER MOLFETTA", value: "molfetta" },
    { label: "IQOS PREMIUM PARTNER MOLINELLA", value: "molinella" },
    { label: "IQOS PREMIUM PARTNER MONCALIERI", value: "moncalieri" },
    { label: "IQOS PREMIUM PARTNER MONDELLO", value: "mondello" },
    { label: "IQOS PREMIUM PARTNER MONDOVI'", value: "mondovi'" },
    { label: "IQOS PREMIUM PARTNER MONDRAGONE", value: "mondragone" },
    { label: "IQOS PREMIUM PARTNER MONFALCONE", value: "monfalcone" },
    { label: "IQOS PREMIUM PARTNER MONOPOLI", value: "monopoli" },
    { label: "IQOS PREMIUM PARTNER MONSUMMANO TERME", value: "monsummano-terme" },
    { label: "IQOS PREMIUM PARTNER MONTALTO DI CASTRO", value: "montalto-di-castro" },
    { label: "IQOS PREMIUM PARTNER MONTALTO UFFUGO", value: "montalto-uffugo" },
    { label: "IQOS PREMIUM PARTNER MONTEBELLUNA", value: "montebelluna" },
    { label: "IQOS PREMIUM PARTNER MONTECATINI TERME", value: "montecatini-terme" },
    { label: "IQOS PREMIUM PARTNER MONTEFORTE IRPINO", value: "monteforte-irpino" },
    { label: "IQOS PREMIUM PARTNER MONTEGROTTO TERME", value: "montegrotto-terme" },
    { label: "IQOS PREMIUM PARTNER MONTEMURLO", value: "montemurlo" },
    { label: "IQOS PREMIUM PARTNER MONTEPAONE", value: "montepaone" },
    { label: "IQOS PREMIUM PARTNER MONTERONI D'ARBIA ", value: "monteroni-d'arbia" },
    { label: "IQOS PREMIUM PARTNER MONTERONI DI LECCE", value: "monteroni-di-lecce" },
    { label: "IQOS PREMIUM PARTNER MONTESACRO", value: "montesacro" },
    { label: "IQOS PREMIUM PARTNER MONTESARCHIO", value: "montesarchio" },
    { label: "IQOS PREMIUM PARTNER MONTESILVANO", value: "montesilvano" },
    { label: "IQOS PREMIUM PARTNER MONTESILVANO CORSO UMBERTO", value: "montesilvano-corso-umberto" },
    { label: "IQOS PREMIUM PARTNER MONTEVARCHI", value: "montevarchi" },
    { label: "IQOS PREMIUM PARTNER MONTEVERDE", value: "monteverde" },
    { label: "IQOS PREMIUM PARTNER MONTI", value: "monti" },
    { label: "IQOS PREMIUM PARTNER MONTICELLI D'ONGINA", value: "monticelli-d'ongina" },
    { label: "IQOS PREMIUM PARTNER MONTICHIARI", value: "montichiari" },
    { label: "IQOS PREMIUM PARTNER MONZA", value: "monza" },
    { label: "IQOS PREMIUM PARTNER MOTTA DI LIVENZA", value: "motta-di-livenza" },
    { label: "IQOS PREMIUM PARTNER MOTTA S.ANASTASIA", value: "motta-s.anastasia" },
    { label: "IQOS PREMIUM PARTNER MULAZZANO", value: "mulazzano" },
    { label: "IQOS PREMIUM PARTNER MURO LUCANO", value: "muro-lucano" },
    { label: "IQOS PREMIUM PARTNER MURRI", value: "murri" },
    { label: "IQOS PREMIUM PARTNER NAPOLI", value: "napoli" },
    { label: "IQOS PREMIUM PARTNER NAPOLI EST", value: "napoli-est" },
    { label: "IQOS PREMIUM PARTNER NAPOLI SANITÀ", value: "napoli-sanità" },
    { label: "IQOS PREMIUM PARTNER NAPOLI VIA TOLEDO", value: "napoli-via-toledo" },
    { label: "IQOS PREMIUM PARTNER NARDO'", value: "nardo'" },
    { label: "IQOS PREMIUM PARTNER NARNI", value: "narni" },
    { label: "IQOS PREMIUM PARTNER NETTUNO", value: "nettuno" },
    { label: "IQOS PREMIUM PARTNER NICASTRO", value: "nicastro" },
    { label: "IQOS PREMIUM PARTNER NICHELINO", value: "nichelino" },
    { label: "IQOS PREMIUM PARTNER NICOLOSI", value: "nicolosi" },
    { label: "IQOS PREMIUM PARTNER NISCEMI", value: "niscemi" },
    { label: "IQOS PREMIUM PARTNER NOCERA INFERIORE", value: "nocera-inferiore" },
    { label: "IQOS PREMIUM PARTNER NOICATTARO", value: "noicattaro" },
    { label: "IQOS PREMIUM PARTNER NOLA", value: "nola" },
    { label: "IQOS PREMIUM PARTNER NOTO", value: "noto" },
    { label: "IQOS PREMIUM PARTNER NOVA MILANESE", value: "nova-milanese" },
    { label: "IQOS PREMIUM PARTNER NOVARA", value: "novara" },
    { label: "IQOS PREMIUM PARTNER NOVARA CENTRO", value: "novara-centro" },
    { label: "IQOS PREMIUM PARTNER NOVENTA PADOVANA", value: "noventa-padovana" },
    { label: "IQOS PREMIUM PARTNER NUORO", value: "nuoro" },
    { label: "IQOS PREMIUM PARTNER OLBIA", value: "olbia" },
    { label: "IQOS PREMIUM PARTNER OLBIA CORSO UMBERTO", value: "olbia-corso-umberto" },
    { label: "IQOS PREMIUM PARTNER OLGIATA", value: "olgiata" },
    { label: "IQOS PREMIUM PARTNER OPERA", value: "opera" },
    { label: "IQOS PREMIUM PARTNER ORBASSANO", value: "orbassano" },
    { label: "IQOS PREMIUM PARTNER ORBETELLO", value: "orbetello" },
    { label: "IQOS PREMIUM PARTNER ORIA", value: "oria" },
    { label: "IQOS PREMIUM PARTNER ORISTANO", value: "oristano" },
    { label: "IQOS PREMIUM PARTNER ORNAGO", value: "ornago" },
    { label: "IQOS PREMIUM PARTNER ORTA DI ATELLA", value: "orta-di-atella" },
    { label: "IQOS PREMIUM PARTNER ORTA NOVA", value: "orta-nova" },
    { label: "IQOS PREMIUM PARTNER ORVIETO", value: "orvieto" },
    { label: "IQOS PREMIUM PARTNER ORZINUOVI", value: "orzinuovi" },
    { label: "IQOS PREMIUM PARTNER OSPEDALETTO", value: "ospedaletto" },
    { label: "IQOS PREMIUM PARTNER OSTIA", value: "ostia" },
    { label: "IQOS PREMIUM PARTNER OSTIA GONDOLE", value: "ostia-gondole" },
    { label: "IQOS PREMIUM PARTNER OSTUNI", value: "ostuni" },
    { label: "IQOS PREMIUM PARTNER OTTAVIANO", value: "ottaviano" },
    { label: "IQOS PREMIUM PARTNER OVADA", value: "ovada" },
    { label: "IQOS PREMIUM PARTNER OZZANO DELL'EMILIA", value: "ozzano-dell'emilia" },
    { label: "IQOS PREMIUM PARTNER OZZANO DELL’EMILIA", value: "ozzano-dell’emilia" },
    { label: "IQOS PREMIUM PARTNER PACHINO", value: "pachino" },
    { label: "IQOS PREMIUM PARTNER PACHINO", value: "pachino" },
    { label: "IQOS PREMIUM PARTNER PADERNO DUGNANO", value: "paderno-dugnano" },
    { label: "IQOS PREMIUM PARTNER PADOVA", value: "padova" },
    { label: "IQOS PREMIUM PARTNER PADOVA ARCELLA", value: "padova-arcella" },
    { label: "IQOS PREMIUM PARTNER PAGANI", value: "pagani" },
    { label: "IQOS PREMIUM PARTNER PALAGIANO", value: "palagiano" },
    { label: "IQOS PREMIUM PARTNER PALAGONIA", value: "palagonia" },
    { label: "IQOS PREMIUM PARTNER PALERMO VIA EMILIA", value: "palermo-via-emilia" },
    { label: "IQOS PREMIUM PARTNER PALESTRINA", value: "palestrina" },
    { label: "IQOS PREMIUM PARTNER PALMANOVA", value: "palmanova" },
    { label: "IQOS PREMIUM PARTNER PALMAROLA", value: "palmarola" },
    { label: "IQOS PREMIUM PARTNER PALMI", value: "palmi" },
    { label: "IQOS PREMIUM PARTNER PANORAMA", value: "panorama" },
    { label: "IQOS PREMIUM PARTNER PAOLA", value: "paola" },
    { label: "IQOS PREMIUM PARTNER PARABIAGO", value: "parabiago" },
    { label: "IQOS PREMIUM PARTNER PARIOLI", value: "parioli" },
    { label: "IQOS PREMIUM PARTNER PARMA", value: "parma" },
    { label: "IQOS PREMIUM PARTNER PARONA", value: "parona" },
    { label: "IQOS PREMIUM PARTNER PARTINICO", value: "partinico" },
    { label: "IQOS PREMIUM PARTNER PATERNÒ PLATANI", value: "paternò-platani" },
    { label: "IQOS PREMIUM PARTNER PATERNO'", value: "paterno'" },
    { label: "IQOS PREMIUM PARTNER PATTI", value: "patti" },
    { label: "IQOS PREMIUM PARTNER PAULLO", value: "paullo" },
    { label: "IQOS PREMIUM PARTNER PAVIA", value: "pavia" },
    { label: "IQOS PREMIUM PARTNER PAVULLO NEL FRIGNANO", value: "pavullo-nel-frignano" },
    { label: "IQOS PREMIUM PARTNER PENNE", value: "penne" },
    { label: "IQOS PREMIUM PARTNER PERGINE VALSUGANA", value: "pergine-valsugana" },
    { label: "IQOS PREMIUM PARTNER PERUGIA", value: "perugia" },
    { label: "IQOS PREMIUM PARTNER PERUGIA CENTRO", value: "perugia-centro" },
    { label: "IQOS PREMIUM PARTNER PERUGIA STAZIONE", value: "perugia-stazione" },
    { label: "IQOS PREMIUM PARTNER PESARO", value: "pesaro" },
    { label: "IQOS PREMIUM PARTNER PESCARA", value: "pescara" },
    { label: "IQOS PREMIUM PARTNER PESCARA CENTRO", value: "pescara-centro" },
    { label: "IQOS PREMIUM PARTNER PESCARA COLLI", value: "pescara-colli" },
    { label: "IQOS PREMIUM PARTNER PESCARA PORTA NUOVA", value: "pescara-porta-nuova" },
    { label: "IQOS PREMIUM PARTNER PESCASSEROLI", value: "pescasseroli" },
    { label: "IQOS PREMIUM PARTNER PESCHIERA BORROMEO", value: "peschiera-borromeo" },
    { label: "IQOS PREMIUM PARTNER PETROCELLI", value: "petrocelli" },
    { label: "IQOS PREMIUM PARTNER PIACENZA", value: "piacenza" },
    { label: "IQOS PREMIUM PARTNER PIANURA", value: "pianura" },
    { label: "IQOS PREMIUM PARTNER PIAZZA ARMERINA", value: "piazza-armerina" },
    { label: "IQOS PREMIUM PARTNER PIAZZA BOLOGNA", value: "piazza-bologna" },
    { label: "IQOS PREMIUM PARTNER PIAZZA CASTELLO", value: "piazza-castello" },
    { label: "IQOS PREMIUM PARTNER PIAZZA DELLA REPUBBLICA", value: "piazza-della-repubblica" },
    { label: "IQOS PREMIUM PARTNER PIAZZALE NOVI LIGURE", value: "piazzale-novi-ligure" },
    { label: "IQOS PREMIUM PARTNER PIAZZALE PORTO VIRO", value: "piazzale-porto-viro" },
    { label: "IQOS PREMIUM PARTNER PIAZZALE ROMA", value: "piazzale-roma" },
    { label: "IQOS PREMIUM PARTNER PIEDIMONTE MATESE", value: "piedimonte-matese" },
    { label: "IQOS PREMIUM PARTNER PIEVE EMANUELE", value: "pieve-emanuele" },
    { label: "IQOS PREMIUM PARTNER PIEVE FISSIRAGA", value: "pieve-fissiraga" },
    { label: "IQOS PREMIUM PARTNER PIGNASECCA", value: "pignasecca" },
    { label: "IQOS PREMIUM PARTNER PIGNETO", value: "pigneto" },
    { label: "IQOS PREMIUM PARTNER PINEROLO", value: "pinerolo" },
    { label: "IQOS PREMIUM PARTNER PINETO", value: "pineto" },
    { label: "IQOS PREMIUM PARTNER PIOLTELLO", value: "pioltello" },
    { label: "IQOS PREMIUM PARTNER PIOMBINO", value: "piombino" },
    { label: "IQOS PREMIUM PARTNER PIOSSASCO", value: "piossasco" },
    { label: "IQOS PREMIUM PARTNER PIOVE DI SACCO", value: "piove-di-sacco" },
    { label: "IQOS PREMIUM PARTNER PISA", value: "pisa" },
    { label: "IQOS PREMIUM PARTNER PISTOIA", value: "pistoia" },
    { label: "IQOS PREMIUM PARTNER PISTOIA NORD", value: "pistoia-nord" },
    { label: "IQOS PREMIUM PARTNER PIZZIGHETTONE", value: "pizzighettone" },
    { label: "IQOS PREMIUM PARTNER PLEBISCITO", value: "plebiscito" },
    { label: "IQOS PREMIUM PARTNER POGGIARDO", value: "poggiardo" },
    { label: "IQOS PREMIUM PARTNER POGGIBONSI", value: "poggibonsi" },
    { label: "IQOS PREMIUM PARTNER POGGIOFRANCO", value: "poggiofranco" },
    { label: "IQOS PREMIUM PARTNER POGGIOMARINO", value: "poggiomarino" },
    { label: "IQOS PREMIUM PARTNER POLICORO", value: "policoro" },
    { label: "IQOS PREMIUM PARTNER POLLICA", value: "pollica" },
    { label: "IQOS PREMIUM PARTNER POMEZIA", value: "pomezia" },
    { label: "IQOS PREMIUM PARTNER POMIGLIANO D'ARCO", value: "pomigliano-d'arco" },
    { label: "IQOS PREMIUM PARTNER PONTASSIEVE", value: "pontassieve" },
    { label: "IQOS PREMIUM PARTNER PONTE BUGGIANESE", value: "ponte-buggianese" },
    { label: "IQOS PREMIUM PARTNER PONTECORVO", value: "pontecorvo" },
    { label: "IQOS PREMIUM PARTNER PONTEDERA", value: "pontedera" },
    { label: "IQOS PREMIUM PARTNER PONTINIA", value: "pontinia" },
    { label: "IQOS PREMIUM PARTNER POPPI", value: "poppi" },
    { label: "IQOS PREMIUM PARTNER PORDENONE", value: "pordenone" },
    { label: "IQOS PREMIUM PARTNER PORLEZZA", value: "porlezza" },
    { label: "IQOS PREMIUM PARTNER PORTA ROMANA", value: "porta-romana" },
    { label: "IQOS PREMIUM PARTNER PORTA S. GENNARO", value: "porta-s.-gennaro" },
    { label: "IQOS PREMIUM PARTNER PORTE DI TORINO", value: "porte-di-torino" },
    { label: "IQOS PREMIUM PARTNER PORTICI", value: "portici" },
    { label: "IQOS PREMIUM PARTNER PORTO AZZURRO", value: "porto-azzurro" },
    { label: "IQOS PREMIUM PARTNER PORTO CERVO", value: "porto-cervo" },
    { label: "IQOS PREMIUM PARTNER PORTO S.ELPIDIO", value: "porto-s.elpidio" },
    { label: "IQOS PREMIUM PARTNER PORTO SAN PAOLO", value: "porto-san-paolo" },
    { label: "IQOS PREMIUM PARTNER PORTO SANTO STEFANO", value: "porto-santo-stefano" },
    { label: "IQOS PREMIUM PARTNER PORTO TORRES", value: "porto-torres" },
    { label: "IQOS PREMIUM PARTNER PORTOFERRAIO", value: "portoferraio" },
    { label: "IQOS PREMIUM PARTNER PORTUENSE", value: "portuense" },
    { label: "IQOS PREMIUM PARTNER POSILLIPO", value: "posillipo" },
    { label: "IQOS PREMIUM PARTNER POSITANO", value: "positano" },
    { label: "IQOS PREMIUM PARTNER POTENZA", value: "potenza" },
    { label: "IQOS PREMIUM PARTNER POTENZA PICENA", value: "potenza-picena" },
    { label: "IQOS PREMIUM PARTNER POZZALLO", value: "pozzallo" },
    { label: "IQOS PREMIUM PARTNER POZZUOLI", value: "pozzuoli" },
    { label: "IQOS PREMIUM PARTNER PRATI", value: "prati" },
    { label: "IQOS PREMIUM PARTNER PRATO", value: "prato" },
    { label: "IQOS PREMIUM PARTNER PRIMAVALLE", value: "primavalle" },
    { label: "IQOS PREMIUM PARTNER PROCIDA", value: "procida" },
    { label: "IQOS PREMIUM PARTNER PULA", value: "pula" },
    { label: "IQOS PREMIUM PARTNER PUTIGNANO", value: "putignano" },
    { label: "IQOS PREMIUM PARTNER QUALIANO", value: "qualiano" },
    { label: "IQOS PREMIUM PARTNER QUARRATA", value: "quarrata" },
    { label: "IQOS PREMIUM PARTNER QUARTIERI SPAGNOLI", value: "quartieri-spagnoli" },
    { label: "IQOS PREMIUM PARTNER QUARTO OGGIARO", value: "quarto-oggiaro" },
    { label: "IQOS PREMIUM PARTNER QUARTU SANT’ELENA", value: "quartu-sant’elena" },
    { label: "IQOS PREMIUM PARTNER RACCONIGI", value: "racconigi" },
    { label: "IQOS PREMIUM PARTNER RAGUSA CORSO ITALIA", value: "ragusa-corso-italia" },
    { label: "IQOS PREMIUM PARTNER RAPALLO", value: "rapallo" },
    { label: "IQOS PREMIUM PARTNER RAVENNA NORD", value: "ravenna-nord" },
    { label: "IQOS PREMIUM PARTNER RAVENNA VIA SANTUCCI", value: "ravenna-via-santucci" },
    { label: "IQOS PREMIUM PARTNER RE DI ROMA", value: "re-di-roma" },
    { label: "IQOS PREMIUM PARTNER RECANATI", value: "recanati" },
    { label: "IQOS PREMIUM PARTNER REGALBUTO", value: "regalbuto" },
    { label: "IQOS PREMIUM PARTNER REGGIO DI CALABRIA CENTRO", value: "reggio-di-calabria-centro" },
    { label: "IQOS PREMIUM PARTNER REGGIO EMILIA RISORGIMENTO", value: "reggio-emilia-risorgimento" },
    { label: "IQOS PREMIUM PARTNER REGGIO NELL'EMILIA", value: "reggio-nell'emilia" },
    { label: "IQOS PREMIUM PARTNER REGGIOLO", value: "reggiolo" },
    { label: "IQOS PREMIUM PARTNER REGIO PARCO", value: "regio-parco" },
    { label: "IQOS PREMIUM PARTNER REMANZACCO", value: "remanzacco" },
    { label: "IQOS PREMIUM PARTNER RENDE", value: "rende" },
    { label: "IQOS PREMIUM PARTNER RHO", value: "rho" },
    { label: "IQOS PREMIUM PARTNER RICCIONE", value: "riccione" },
    { label: "IQOS PREMIUM PARTNER RIETI", value: "rieti" },
    { label: "IQOS PREMIUM PARTNER RIETI CENTRO", value: "rieti-centro" },
    { label: "IQOS PREMIUM PARTNER RIMINI", value: "rimini" },
    { label: "IQOS PREMIUM PARTNER RIMINI CENTRO", value: "rimini-centro" },
    { label: "IQOS PREMIUM PARTNER RIONE ALTO", value: "rione-alto" },
    { label: "IQOS PREMIUM PARTNER RIPAMONTI", value: "ripamonti" },
    { label: "IQOS PREMIUM PARTNER RIPOSTO", value: "riposto" },
    { label: "IQOS PREMIUM PARTNER RIVA DEL GARDA", value: "riva-del-garda" },
    { label: "IQOS PREMIUM PARTNER RIVAROLO CANAVESE", value: "rivarolo-canavese" },
    { label: "IQOS PREMIUM PARTNER RIVOLI", value: "rivoli" },
    { label: "IQOS PREMIUM PARTNER ROCCALUMERA", value: "roccalumera" },
    { label: "IQOS PREMIUM PARTNER ROMA AXA", value: "roma-axa" },
    { label: "IQOS PREMIUM PARTNER ROMA BALDUINA", value: "roma-balduina" },
    { label: "IQOS PREMIUM PARTNER ROMA PANTHEON", value: "roma-pantheon" },
    { label: "IQOS PREMIUM PARTNER ROMANINA", value: "romanina" },
    { label: "IQOS PREMIUM PARTNER ROMANO DI LOMBARDIA", value: "romano-di-lombardia" },
    { label: "IQOS PREMIUM PARTNER RONCIGLIONE", value: "ronciglione" },
    { label: "IQOS PREMIUM PARTNER ROOSEVELT", value: "roosevelt" },
    { label: "IQOS PREMIUM PARTNER ROSA'", value: "rosa'" },
    { label: "IQOS PREMIUM PARTNER ROSIGNANO MARITTIMO", value: "rosignano-marittimo" },
    { label: "IQOS PREMIUM PARTNER ROSOLINI", value: "rosolini" },
    { label: "IQOS PREMIUM PARTNER ROSSANO STAZIONE", value: "rossano-stazione" },
    { label: "IQOS PREMIUM PARTNER ROVATO", value: "rovato" },
    { label: "IQOS PREMIUM PARTNER ROVERETO", value: "rovereto" },
    { label: "IQOS PREMIUM PARTNER ROVIGO", value: "rovigo" },
    { label: "IQOS PREMIUM PARTNER ROZZANO", value: "rozzano" },
    { label: "IQOS PREMIUM PARTNER RUBANO LE BRENTELLE", value: "rubano-le-brentelle" },
    { label: "IQOS PREMIUM PARTNER RUBIERA", value: "rubiera" },
    { label: "IQOS PREMIUM PARTNER RUSSI", value: "russi" },
    { label: "IQOS PREMIUM PARTNER S. MARGHERITA DI BELICE", value: "s.-margherita-di-belice" },
    { label: "IQOS PREMIUM PARTNER S.AGATA DI MILITELLO", value: "s.agata-di-militello" },
    { label: "IQOS PREMIUM PARTNER S.AGATA LI BATTIATI", value: "s.agata-li-battiati" },
    { label: "IQOS PREMIUM PARTNER S.ANASTASIA", value: "s.anastasia" },
    { label: "IQOS PREMIUM PARTNER S.ANDREA APOSTOLO DELLO IONIO", value: "s.andrea-apostolo-dello-ionio" },
    { label: "IQOS PREMIUM PARTNER S.BENEDETTO DEL TRONTO", value: "s.benedetto-del-tronto" },
    { label: "IQOS PREMIUM PARTNER S.CATALDO", value: "s.cataldo" },
    { label: "IQOS PREMIUM PARTNER S.DONA' DI PIAVE", value: "s.dona'-di-piave" },
    { label: "IQOS PREMIUM PARTNER S.EGIDIO DEL MONTE ALBINO", value: "s.egidio-del-monte-albino" },
    { label: "IQOS PREMIUM PARTNER S.FERDINANDO DI PUGLIA ", value: "s.ferdinando-di-puglia" },
    { label: "IQOS PREMIUM PARTNER S.GIORGIO A CREMANO", value: "s.giorgio-a-cremano" },
    { label: "IQOS PREMIUM PARTNER S.GIORGIO BIGARELLO", value: "s.giorgio-bigarello" },
    { label: "IQOS PREMIUM PARTNER S.GIORGIO PIANO", value: "s.giorgio-piano" },
    { label: "IQOS PREMIUM PARTNER S.GIOVANNI IN FIORE", value: "s.giovanni-in-fiore" },
    { label: "IQOS PREMIUM PARTNER S.GIOVANNI LA PUNTA", value: "s.giovanni-la-punta" },
    { label: "IQOS PREMIUM PARTNER S.GIOVANNI ROTONDO", value: "s.giovanni-rotondo" },
    { label: "IQOS PREMIUM PARTNER S.GIULIANO MILANESE", value: "s.giuliano-milanese" },
    { label: "IQOS PREMIUM PARTNER S.ILARIO D'ENZA", value: "s.ilario-d'enza" },
    { label: "IQOS PREMIUM PARTNER S.M. MADDALENA", value: "s.m.-maddalena" },
    { label: "IQOS PREMIUM PARTNER S.MARIA CAPUA VETERE", value: "s.maria-capua-vetere" },
    { label: "IQOS PREMIUM PARTNER S.MARINELLA", value: "s.marinella" },
    { label: "IQOS PREMIUM PARTNER S.MAURO PASCOLI", value: "s.mauro-pascoli" },
    { label: "IQOS PREMIUM PARTNER S.Mauro Torinese", value: "s.mauro-torinese" },
    { label: "IQOS PREMIUM PARTNER S.MINIATO", value: "s.miniato" },
    { label: "IQOS PREMIUM PARTNER S.NICANDRO GARGANICO", value: "s.nicandro-garganico" },
    { label: "IQOS PREMIUM PARTNER S.NICOLA LA STRADA", value: "s.nicola-la-strada" },
    { label: "IQOS PREMIUM PARTNER S.PELLEGRINO TERME", value: "s.pellegrino-terme" },
    { label: "IQOS PREMIUM PARTNER S.POLO D'ENZA", value: "s.polo-d'enza" },
    { label: "IQOS PREMIUM PARTNER S.SECONDO PARMENSE", value: "s.secondo-parmense" },
    { label: "IQOS PREMIUM PARTNER S.SEVERO", value: "s.severo" },
    { label: "IQOS PREMIUM PARTNER S.VITO DEI NORMANNI", value: "s.vito-dei-normanni" },
    { label: "IQOS PREMIUM PARTNER S.VITO LO CAPO", value: "s.vito-lo-capo" },
    { label: "IQOS PREMIUM PARTNER SABAUDIA", value: "sabaudia" },
    { label: "IQOS PREMIUM PARTNER SACILE", value: "sacile" },
    { label: "IQOS PREMIUM PARTNER SALA CONSILINA", value: "sala-consilina" },
    { label: "IQOS PREMIUM PARTNER SALERNO", value: "salerno" },
    { label: "IQOS PREMIUM PARTNER SALERNO PASTENA", value: "salerno-pastena" },
    { label: "IQOS PREMIUM PARTNER SALÒ", value: "salò" },
    { label: "IQOS PREMIUM PARTNER SALUZZO", value: "saluzzo" },
    { label: "IQOS PREMIUM PARTNER SAMPIERDARENA", value: "sampierdarena" },
    { label: "IQOS PREMIUM PARTNER SAMPOLO", value: "sampolo" },
    { label: "IQOS PREMIUM PARTNER SAN BONIFACIO", value: "san-bonifacio" },
    { label: "IQOS PREMIUM PARTNER SAN CARLO", value: "san-carlo" },
    { label: "IQOS PREMIUM PARTNER SAN CESAREO", value: "san-cesareo" },
    { label: "IQOS PREMIUM PARTNER SAN FIOR", value: "san-fior" },
    { label: "IQOS PREMIUM PARTNER SAN GIORGIO SU LEGNANO", value: "san-giorgio-su-legnano" },
    { label: "IQOS PREMIUM PARTNER SAN GIOVANNI A TEDUCCIO", value: "san-giovanni-a-teduccio" },
    { label: "IQOS PREMIUM PARTNER SAN MARTINO", value: "san-martino" },
    { label: "IQOS PREMIUM PARTNER SAN PAOLO", value: "san-paolo" },
    { label: "IQOS PREMIUM PARTNER SAN SALVARIO", value: "san-salvario" },
    { label: "IQOS PREMIUM PARTNER SAN TEODORO", value: "san-teodoro" },
    { label: "IQOS PREMIUM PARTNER SANGIANO", value: "sangiano" },
    { label: "IQOS PREMIUM PARTNER SANLURI", value: "sanluri" },
    { label: "IQOS PREMIUM PARTNER SANNAZZARO DE'BURGONDI", value: "sannazzaro-de'burgondi" },
    { label: "IQOS PREMIUM PARTNER SANREMO", value: "sanremo" },
    { label: "IQOS PREMIUM PARTNER SANSEPOLCRO", value: "sansepolcro" },
    { label: "IQOS PREMIUM PARTNER SANSOVINO", value: "sansovino" },
    { label: "IQOS PREMIUM PARTNER SANT'ANTIMO", value: "sant'antimo" },
    { label: "IQOS PREMIUM PARTNER SANT’ANTONIO", value: "sant’antonio" },
    { label: "IQOS PREMIUM PARTNER SANTA RITA", value: "santa-rita" },
    { label: "IQOS PREMIUM PARTNER SANTA TERESA DI GALLURA", value: "santa-teresa-di-gallura" },
    { label: "IQOS PREMIUM PARTNER SANTHIA'", value: "santhia'" },
    { label: "IQOS PREMIUM PARTNER SAPRI", value: "sapri" },
    { label: "IQOS PREMIUM PARTNER SARNICO", value: "sarnico" },
    { label: "IQOS PREMIUM PARTNER SARNO", value: "sarno" },
    { label: "IQOS PREMIUM PARTNER SARONNO", value: "saronno" },
    { label: "IQOS PREMIUM PARTNER SARZANA", value: "sarzana" },
    { label: "IQOS PREMIUM PARTNER SASSARI", value: "sassari" },
    { label: "IQOS PREMIUM PARTNER SASSUOLO", value: "sassuolo" },
    { label: "IQOS PREMIUM PARTNER SAVIGLIANO", value: "savigliano" },
    { label: "IQOS PREMIUM PARTNER SAVONA", value: "savona" },
    { label: "IQOS PREMIUM PARTNER SCAFATI", value: "scafati" },
    { label: "IQOS PREMIUM PARTNER SCAGLIARINI", value: "scagliarini" },
    { label: "IQOS PREMIUM PARTNER SCAMPIA", value: "scampia" },
    { label: "IQOS PREMIUM PARTNER SCANDICCI", value: "scandicci" },
    { label: "IQOS PREMIUM PARTNER SCIACCA", value: "sciacca" },
    { label: "IQOS PREMIUM PARTNER SECONDIGLIANO", value: "secondigliano" },
    { label: "IQOS PREMIUM PARTNER SEDRIANO", value: "sedriano" },
    { label: "IQOS PREMIUM PARTNER SEGRATE", value: "segrate" },
    { label: "IQOS PREMIUM PARTNER SELARGIUS", value: "selargius" },
    { label: "IQOS PREMIUM PARTNER SENAGO", value: "senago" },
    { label: "IQOS PREMIUM PARTNER SENIGALLIA", value: "senigallia" },
    { label: "IQOS PREMIUM PARTNER SENISE", value: "senise" },
    { label: "IQOS PREMIUM PARTNER SEREGNO", value: "seregno" },
    { label: "IQOS PREMIUM PARTNER SERRAVALLE SCRIVIA", value: "serravalle-scrivia" },
    { label: "IQOS PREMIUM PARTNER SESSA AURUNCA", value: "sessa-aurunca" },
    { label: "IQOS PREMIUM PARTNER SESTO CALENDE", value: "sesto-calende" },
    { label: "IQOS PREMIUM PARTNER SESTO S.GIOVANNI", value: "sesto-s.giovanni" },
    { label: "IQOS PREMIUM PARTNER SESTRI LEVANTE", value: "sestri-levante" },
    { label: "IQOS PREMIUM PARTNER SESTRI PONENTE", value: "sestri-ponente" },
    { label: "IQOS PREMIUM PARTNER SESTRIERE", value: "sestriere" },
    { label: "IQOS PREMIUM PARTNER SESTU", value: "sestu" },
    { label: "IQOS PREMIUM PARTNER SETTIMO TORINESE", value: "settimo-torinese" },
    { label: "IQOS PREMIUM PARTNER SEZZE", value: "sezze" },
    { label: "IQOS PREMIUM PARTNER SIDERNO", value: "siderno" },
    { label: "IQOS PREMIUM PARTNER SIENA", value: "siena" },
    { label: "IQOS PREMIUM PARTNER SILEA", value: "silea" },
    { label: "IQOS PREMIUM PARTNER SILVI", value: "silvi" },
    { label: "IQOS PREMIUM PARTNER SINALUNGA", value: "sinalunga" },
    { label: "IQOS PREMIUM PARTNER SIRACUSA", value: "siracusa" },
    { label: "IQOS PREMIUM PARTNER SIRACUSA POLIBIO", value: "siracusa-polibio" },
    { label: "IQOS PREMIUM PARTNER SISMONDI", value: "sismondi" },
    { label: "IQOS PREMIUM PARTNER SOCCAVO", value: "soccavo" },
    { label: "IQOS PREMIUM PARTNER SOLBIATE ARNO", value: "solbiate-arno" },
    { label: "IQOS PREMIUM PARTNER SOLBIATE CON CAGNO", value: "solbiate-con-cagno" },
    { label: "IQOS PREMIUM PARTNER SOLOFRA", value: "solofra" },
    { label: "IQOS PREMIUM PARTNER SOMMA VESUVIANA", value: "somma-vesuviana" },
    { label: "IQOS PREMIUM PARTNER SONDRIO", value: "sondrio" },
    { label: "IQOS PREMIUM PARTNER SORA", value: "sora" },
    { label: "IQOS PREMIUM PARTNER SORBARA DI BOMPORTO", value: "sorbara-di-bomporto" },
    { label: "IQOS PREMIUM PARTNER SORBOLO MEZZANI", value: "sorbolo-mezzani" },
    { label: "IQOS PREMIUM PARTNER SORRENTO", value: "sorrento" },
    { label: "IQOS PREMIUM PARTNER SPINEA", value: "spinea" },
    { label: "IQOS PREMIUM PARTNER SPOLETO", value: "spoleto" },
    { label: "IQOS PREMIUM PARTNER SPOLTORE", value: "spoltore" },
    { label: "IQOS PREMIUM PARTNER STADIO", value: "stadio" },
    { label: "IQOS PREMIUM PARTNER STATUARIO", value: "statuario" },
    { label: "IQOS PREMIUM PARTNER STRASBURGO", value: "strasburgo" },
    { label: "IQOS PREMIUM PARTNER SUSA", value: "susa" },
    { label: "IQOS PREMIUM PARTNER SUZZARA", value: "suzzara" },
    { label: "IQOS PREMIUM PARTNER TARANTO", value: "taranto" },
    { label: "IQOS PREMIUM PARTNER TARANTO BORGO", value: "taranto-borgo" },
    { label: "IQOS PREMIUM PARTNER TARANTO MONTEGRANARO", value: "taranto-montegranaro" },
    { label: "IQOS PREMIUM PARTNER TARQUINIA", value: "tarquinia" },
    { label: "IQOS PREMIUM PARTNER TAURIANOVA", value: "taurianova" },
    { label: "IQOS PREMIUM PARTNER TAVIANO", value: "taviano" },
    { label: "IQOS PREMIUM PARTNER TEANO", value: "teano" },
    { label: "IQOS PREMIUM PARTNER TELESE TERME", value: "telese-terme" },
    { label: "IQOS PREMIUM PARTNER TERAMO", value: "teramo" },
    { label: "IQOS PREMIUM PARTNER TERAMO CENTRO", value: "teramo-centro" },
    { label: "IQOS PREMIUM PARTNER TERMINI IMERESE", value: "termini-imerese" },
    { label: "IQOS PREMIUM PARTNER TERMOLI", value: "termoli" },
    { label: "IQOS PREMIUM PARTNER TERNI", value: "terni" },
    { label: "IQOS PREMIUM PARTNER TERRACINA", value: "terracina" },
    { label: "IQOS PREMIUM PARTNER TERRALBA", value: "terralba" },
    { label: "IQOS PREMIUM PARTNER TERRANUOVA BRACCIOLINI", value: "terranuova-bracciolini" },
    { label: "IQOS PREMIUM PARTNER TERRASINI", value: "terrasini" },
    { label: "IQOS PREMIUM PARTNER TESTACCIO", value: "testaccio" },
    { label: "IQOS PREMIUM PARTNER TEVEROLA", value: "teverola" },
    { label: "IQOS PREMIUM PARTNER TIVOLI", value: "tivoli" },
    { label: "IQOS PREMIUM PARTNER TODI", value: "todi" },
    { label: "IQOS PREMIUM PARTNER TOLENTINO", value: "tolentino" },
    { label: "IQOS PREMIUM PARTNER TOLMEZZO", value: "tolmezzo" },
    { label: "IQOS PREMIUM PARTNER TOR BELLA MONACA", value: "tor-bella-monaca" },
    { label: "IQOS PREMIUM PARTNER TORRE ANNUNZIATA", value: "torre-annunziata" },
    { label: "IQOS PREMIUM PARTNER TORRE BOLDONE", value: "torre-boldone" },
    { label: "IQOS PREMIUM PARTNER TORRE DEL GRECO", value: "torre-del-greco" },
    { label: "IQOS PREMIUM PARTNER TORREGROTTA", value: "torregrotta" },
    { label: "IQOS PREMIUM PARTNER TORRELUNGA", value: "torrelunga" },
    { label: "IQOS PREMIUM PARTNER TORRESPACCATA", value: "torrespaccata" },
    { label: "IQOS PREMIUM PARTNER TORRI DI QUARTESOLO", value: "torri-di-quartesolo" },
    { label: "IQOS PREMIUM PARTNER TORTOLI'", value: "tortoli'" },
    { label: "IQOS PREMIUM PARTNER TORTONA", value: "tortona" },
    { label: "IQOS PREMIUM PARTNER TOSCOLANO MADERNO", value: "toscolano-maderno" },
    { label: "IQOS PREMIUM PARTNER TRADATE", value: "tradate" },
    { label: "IQOS PREMIUM PARTNER TRANI", value: "trani" },
    { label: "IQOS PREMIUM PARTNER TRAONA", value: "traona" },
    { label: "IQOS PREMIUM PARTNER TRASTEVERE", value: "trastevere" },
    { label: "IQOS PREMIUM PARTNER TRAVAGLIATO", value: "travagliato" },
    { label: "IQOS PREMIUM PARTNER TREBISACCE", value: "trebisacce" },
    { label: "IQOS PREMIUM PARTNER TRECATE", value: "trecate" },
    { label: "IQOS PREMIUM PARTNER TREMESTIERI ETNEO", value: "tremestieri-etneo" },
    { label: "IQOS PREMIUM PARTNER TRENTO", value: "trento" },
    { label: "IQOS PREMIUM PARTNER TREVIGLIO", value: "treviglio" },
    { label: "IQOS PREMIUM PARTNER TREVIOLO", value: "treviolo" },
    { label: "IQOS PREMIUM PARTNER TREVISO", value: "treviso" },
    { label: "IQOS PREMIUM PARTNER TRIBUNALE", value: "tribunale" },
    { label: "IQOS PREMIUM PARTNER TRICASE", value: "tricase" },
    { label: "IQOS PREMIUM PARTNER TRIESTE", value: "trieste" },
    { label: "IQOS PREMIUM PARTNER TRIESTE OPICINA", value: "trieste-opicina" },
    { label: "IQOS PREMIUM PARTNER TRIONFALE", value: "trionfale" },
    { label: "IQOS PREMIUM PARTNER TROPEA", value: "tropea" },
    { label: "IQOS PREMIUM PARTNER UDINE", value: "udine" },
    { label: "IQOS PREMIUM PARTNER UDINE CENTRO", value: "udine-centro" },
    { label: "IQOS PREMIUM PARTNER URBINO", value: "urbino" },
    { label: "IQOS PREMIUM PARTNER URGNANO", value: "urgnano" },
    { label: "IQOS PREMIUM PARTNER UTA", value: "uta" },
    { label: "IQOS PREMIUM PARTNER VALENZA", value: "valenza" },
    { label: "IQOS PREMIUM PARTNER VALLO DELLA LUCANIA", value: "vallo-della-lucania" },
    { label: "IQOS PREMIUM PARTNER VALLONE PETRARA", value: "vallone-petrara" },
    { label: "IQOS PREMIUM PARTNER VARALLO", value: "varallo" },
    { label: "IQOS PREMIUM PARTNER VARAZZE", value: "varazze" },
    { label: "IQOS PREMIUM PARTNER VARCATURO", value: "varcaturo" },
    { label: "IQOS PREMIUM PARTNER VARESE", value: "varese" },
    { label: "IQOS PREMIUM PARTNER VASTO", value: "vasto" },
    { label: "IQOS PREMIUM PARTNER VECCHIANO", value: "vecchiano" },
    { label: "IQOS PREMIUM PARTNER VEGLIE", value: "veglie" },
    { label: "IQOS PREMIUM PARTNER VELLETRI", value: "velletri" },
    { label: "IQOS PREMIUM PARTNER VENAFRO", value: "venafro" },
    { label: "IQOS PREMIUM PARTNER VENARIA REALE", value: "venaria-reale" },
    { label: "IQOS PREMIUM PARTNER VENEZIA", value: "venezia" },
    { label: "IQOS PREMIUM PARTNER VENOSA", value: "venosa" },
    { label: "IQOS PREMIUM PARTNER VERBANIA", value: "verbania" },
    { label: "IQOS PREMIUM PARTNER VERCELLI", value: "vercelli" },
    { label: "IQOS PREMIUM PARTNER VEROLI", value: "veroli" },
    { label: "IQOS PREMIUM PARTNER VERONA", value: "verona" },
    { label: "IQOS PREMIUM PARTNER VERRES", value: "verres" },
    { label: "IQOS PREMIUM PARTNER VERUCCHIO", value: "verucchio" },
    { label: "IQOS PREMIUM PARTNER VESCOVIO", value: "vescovio" },
    { label: "IQOS PREMIUM PARTNER VETRALLA", value: "vetralla" },
    { label: "IQOS PREMIUM PARTNER VIA BERGAMO", value: "via-bergamo" },
    { label: "IQOS PREMIUM PARTNER VIA CARDUCCI", value: "via-carducci" },
    { label: "IQOS PREMIUM PARTNER VIA CERNAIA", value: "via-cernaia" },
    { label: "IQOS PREMIUM PARTNER VIA GIUSEPPINA", value: "via-giuseppina" },
    { label: "IQOS PREMIUM PARTNER VIA ITALIA", value: "via-italia" },
    { label: "IQOS PREMIUM PARTNER VIA LEONARDO GALLUCCI", value: "via-leonardo-gallucci" },
    { label: "IQOS PREMIUM PARTNER VIA LOCATELLI", value: "via-locatelli" },
    { label: "IQOS PREMIUM PARTNER VIA MATTEI", value: "via-mattei" },
    { label: "IQOS PREMIUM PARTNER VIA MEDA", value: "via-meda" },
    { label: "IQOS PREMIUM PARTNER VIA NOVARA", value: "via-novara" },
    { label: "IQOS PREMIUM PARTNER VIA PAOLO SARPI", value: "via-paolo-sarpi" },
    { label: "IQOS PREMIUM PARTNER VIA PIETRO COSSA", value: "via-pietro-cossa" },
    { label: "IQOS PREMIUM PARTNER VIA SALEMI", value: "via-salemi" },
    { label: "IQOS PREMIUM PARTNER VIA SPARTACO", value: "via-spartaco" },
    { label: "IQOS PREMIUM PARTNER VIA VENETO", value: "via-veneto" },
    { label: "IQOS PREMIUM PARTNER VIADANA", value: "viadana" },
    { label: "IQOS PREMIUM PARTNER VIALE DELLA PACE", value: "viale-della-pace" },
    { label: "IQOS PREMIUM PARTNER VIALE PASTEUR", value: "viale-pasteur" },
    { label: "IQOS PREMIUM PARTNER VIALE SOMALIA", value: "viale-somalia" },
    { label: "IQOS PREMIUM PARTNER VIAREGGIO", value: "viareggio" },
    { label: "IQOS PREMIUM PARTNER VIBO VALENTIA", value: "vibo-valentia" },
    { label: "IQOS PREMIUM PARTNER VIBO VALENTIA MARINA", value: "vibo-valentia-marina" },
    { label: "IQOS PREMIUM PARTNER VICO EQUENSE", value: "vico-equense" },
    { label: "IQOS PREMIUM PARTNER VIESTE", value: "vieste" },
    { label: "IQOS PREMIUM PARTNER VIGARANO MAINARDA", value: "vigarano-mainarda" },
    { label: "IQOS PREMIUM PARTNER VIGEVANO", value: "vigevano" },
    { label: "IQOS PREMIUM PARTNER VIGNOLA", value: "vignola" },
    { label: "IQOS PREMIUM PARTNER VIGONZA", value: "vigonza" },
    { label: "IQOS PREMIUM PARTNER VILLA CELLA", value: "villa-cella" },
    { label: "IQOS PREMIUM PARTNER VILLA S.GIOVANNI", value: "villa-s.giovanni" },
    { label: "IQOS PREMIUM PARTNER VILLAFRANCA DI VERONA", value: "villafranca-di-verona" },
    { label: "IQOS PREMIUM PARTNER VILLAGGIO S. AGATA", value: "villaggio-s.-agata" },
    { label: "IQOS PREMIUM PARTNER VILLAPIANA", value: "villapiana" },
    { label: "IQOS PREMIUM PARTNER VILLASIMIUS", value: "villasimius" },
    { label: "IQOS PREMIUM PARTNER VIMERCATE", value: "vimercate" },
    { label: "IQOS PREMIUM PARTNER VITERBO", value: "viterbo" },
    { label: "IQOS PREMIUM PARTNER VITERBO BARCO", value: "viterbo-barco" },
    { label: "IQOS PREMIUM PARTNER VITINIA", value: "vitinia" },
    { label: "IQOS PREMIUM PARTNER VITTORIA", value: "vittoria" },
    { label: "IQOS PREMIUM PARTNER VITTORIO VENETO", value: "vittorio-veneto" },
    { label: "IQOS PREMIUM PARTNER VITTUONE", value: "vittuone" },
    { label: "IQOS PREMIUM PARTNER VOBARNO", value: "vobarno" },
    { label: "IQOS PREMIUM PARTNER VOGHERA", value: "voghera" },
    { label: "IQOS PREMIUM PARTNER VOLLA", value: "volla" },
    { label: "IQOS PREMIUM PARTNER VOMERO", value: "vomero" },
    { label: "IQOS PREMIUM PARTNER WAGNER", value: "wagner" },
    { label: "IQOS PREMIUM PARTNER XX SETTEMBRE", value: "xx-settembre" },
    { label: "IQOS PREMIUM PARTNER ZAGAROLO", value: "zagarolo" },
    { label: "IQOS PREMIUM PARTNER ZANE'", value: "zane'" },
    { label: "IQOS PREMIUM PARTNER ZISA", value: "zisa" }
];