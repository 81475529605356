import React, {useEffect, useState} from "react";
import {firestore} from "../../firebase/clientApp";
import {Box, Container, Stack, Typography, useTheme} from "@mui/material";
import {Textfit} from "react-textfit";
import {QuestionSwitch} from "./QuestionSwitch";
import pleshDark from "../../theme/pleshDark";

function QuestionBar({tipo, domanda, colore}) {
    const theme = useTheme()
    return (
        <Container sx={{height: '25%'}}>
            <Box sx={{position: 'relative', width: '100%', height:'100%'}}>
                <Textfit mode={'multi'} min={10} max={25}
                         style={{
                             color: '#04d1d0', //tipo === 'color' ? (theme.palette.getContrastText(colore || pleshDark.palette.background.paper)) : theme.palette.text,
                             width: '100%',
                             height: '100%',
                             textAlign: 'center',
                             display: 'flex',
                             alignItems: 'center',
                             justifyContent: 'center',
                             fontWeight:'bold'
                         }}>
                    {domanda}
                </Textfit>
            </Box>
        </Container>
    )
}

function QuestionContent({elementPlaying, theme}) {
    return(
        <Container sx={{height:'75%', position:'relative', paddingTop:'20px', textAlign:'center'}}>
            <QuestionSwitch elementPlaying={elementPlaying} theme={theme}/>
        </Container>
    )
}


export default function QuestionDialogContent({url, user, idPlaying, open, theme}) {
    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(() => {
        idPlaying && getElementPlaying()
    }, [idPlaying])

    function getElementPlaying() {
        firestore.collection(url+'/queue').doc(idPlaying).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }
    return(
        <Stack id={'questionDialogBg'} justifyContent={"space-between"} style={{height: '100%', zIndex: 1}}>
            <Box display={'flex'} justifyContent={'center'} width={'100%'}>
                <img src={'/images/premium-logo.svg'} alt={'logo'} style={{width: '70%', marginTop: '8vh'}}/>
            </Box>

            <QuestionBar idPlaying={idPlaying} tipo={elementPlaying.tipo} colore={elementPlaying.colore}
                         domanda={elementPlaying.domanda}/>
            <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                <Typography variant={'h6'} sx={{
                    position: 'relative',
                    "&::after": {
                        content: '""',
                        position: 'absolute',
                        bottom: 0, // Posiziona il bordo alla base del testo
                        left: '33%', // Inizia da metà
                        width: '67%', // Lunghezza del bordo
                        height: '4px', // Spessore del bordo
                        backgroundColor: '#03d1d1', // Colore del bordo
                    },
                }}>
                    Seleziona la risposta corretta
                </Typography>
            </Box>

            <QuestionContent elementPlaying={elementPlaying} user={user} theme={theme}/>
        </Stack>
    )
}
