import {Box, Stack, Typography} from "@mui/material";


export default function Programma() {
    return (
        <Box sx={{
            width:'100%',
            height:'100%',
            margin:0,
            overflow:'hidden',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <img
                src={'/images/programma.png'}
                alt="Full screen"
                style={{
                    width:'100%',
                    height:'100%',
                    objectFit:'cover'
                }}
            />
        </Box>
        /*<Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <Typography variant={'h5'} color={'primary'} fontWeight={'bold'} mt={'2vh'}>
                IL PROGRAMMA
            </Typography>
            <Stack mt={'2vh'} gap={'2vh'}>
                <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'flex-start'} gap={'10px'}>
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'} sx={{borderBottom:'4px solid #03d1d1'}}  >
                        14:30
                    </Typography>
                    <img src={'/images/icons/program-icon-1.svg'} alt={'icon'} width={'20px'} />
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'}  >
                        APERTURA VILLAGGIO & STREET FOOD
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'flex-start'} gap={'10px'}>
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'} sx={{borderBottom:'4px solid #03d1d1'}}  >
                        15:00
                    </Typography>
                    <img src={'/images/icons/program-icon-2.svg'} alt={'icon'} width={'20px'} />
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'}  >
                        RADIO IQOS
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'flex-start'} gap={'10px'}>
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'} sx={{borderBottom:'4px solid #03d1d1'}}  >
                        15:30
                    </Typography>
                    <img src={'/images/icons/program-icon-3.svg'} alt={'icon'} width={'20px'} />
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'}  >
                        ANIMAZIONE E ATTIVITÀ
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'flex-start'} gap={'10px'}>
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'} sx={{borderBottom:'4px solid #03d1d1'}}  >
                        17:15
                    </Typography>
                    <img src={'/images/icons/program-icon-5.svg'} alt={'icon'} width={'20px'} />
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'}  >
                        INTERVENTI ISTITUZIONALI
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'flex-start'} gap={'10px'}>
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'} sx={{borderBottom:'4px solid #03d1d1'}}  >
                        18:00
                    </Typography>
                    <img src={'/images/icons/program-icon-6.svg'} alt={'icon'} width={'20px'} />
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'}  >
                        SHOW MUSICALE
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'flex-start'} gap={'10px'}>
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'} sx={{borderBottom:'4px solid #03d1d1'}}  >
                        19:00
                    </Typography>
                    <img src={'/images/icons/program-icon-7.svg'} alt={'icon'} width={'20px'} />
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'}  >
                        CHILL OUT & STREET FOOD
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'flex-start'} gap={'10px'}>
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'} sx={{borderBottom:'4px solid #03d1d1'}}  >
                        22:00
                    </Typography>
                    <img src={'/images/icons/program-icon-8.svg'} alt={'icon'} width={'20px'} />
                    <Typography variant={'body'} color={'#fff'} fontWeight={'bold'}  >
                        FINE EVENTO
                    </Typography>
                </Box>
            </Stack>
        </Box>*/
    )
}