import {Box, Typography} from "@mui/material";
import {useEffect, useState} from "react";


export default function Mappa() {
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

    const updateOrientation = () => {
        setIsPortrait(window.innerHeight > window.innerWidth);
    };

    useEffect(() => {
        // Listener per il resize o il cambio di orientamento
        window.addEventListener('resize', updateOrientation);

        // Cleanup del listener
        return () => {
            window.removeEventListener('resize', updateOrientation);
        };
    }, []);

    return(
        <Box sx={{
            width:'100%',
            height:'100%',
            margin:0,
            overflow:'hidden',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <img
                src={isPortrait ? '/images/IQOS_MAP_VERT.png' : '/images/IQOS_MAP_HOR.png'}
                alt="Full screen"
                style={{
                    width:'100%',
                    height:'100%',
                    objectFit:'cover'
                }}
            />
        </Box>
    )
}