export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#EA4D95', '#E76E51'],
        home_question: ['#E76E51', '#EA4D95'],
        applausometro: ['#E5634A', '#E5448A'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#EA4D95', '#E76E51'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#00d1d2',
    secondary: '#263238',
    accent: '#EA4D95',
    text: '#eceff1',
    background:"#34303d",
    poll:{
        default:"#414272",
        answered:"#E5448A",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}

export const homeButtons = [
    {id: "mappa", label: "MAPPA", img: <img src={'/images/icons/mappa-icon.svg'} width={'35px'} alt={'icon'}/>},
    {id: "programma", label: "PROGRAMMA", img: <img src={'/images/icons/programma-icon.svg'} width={'35px'} alt={'icon'}/>},
    {id: "food", label: "FOOD", img: <img src={'/images/icons/food-icon.svg'} width={'35px'} alt={'icon'}/>},
]