import React from "react";
import {Box, List, ListItem, Typography} from "@mui/material";

export default function DolciCollapseContent() {
    return (
        <>
            <List>
                <ListItem sx={{paddingLeft: "10%", display: 'flex', alignItems: 'center'}}>
                    <Typography fontWeight={"bold"} sx={{borderBottom: "6px solid #03d1d1"}}>
                        Cannoli siciliani decorati con:
                    </Typography>
                    <img src={'/images/food-icons/icon-sicilia.svg'} alt={''} width={'30px'}
                         style={{marginLeft: '5px'}}/>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>Granella di nocciole</li>
                <li style={{color: "#fff"}}>Granella di pistacchi</li>
                <li style={{color: "#fff"}}>Gocce di cioccolato</li>
                <li style={{color: "#fff"}}>Canditi <br/><br/></li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Mini cannoli di frolla con crema alla soia
                        <img src={'/images/food-icons/icon-sicilia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-vegano.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Mini babà
                        <img src={'/images/food-icons/icon-campania.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Cubi di cioccolato
                        <img src={'/images/food-icons/icon-italia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Cubi di cioccolato Vegan
                        <img src={'/images/food-icons/icon-italia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-vegano.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Cheesecake all’italiana ai frutti rossi
                        <img src={'/images/food-icons/icon-italia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Cheesecake all’italiana ai frutti rossi Vegan
                        <img src={'/images/food-icons/icon-italia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-vegano.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>

            </ul>
            <List>
                <ListItem sx={{paddingLeft: "10%"}}>
                    <Typography fontWeight={"bold"} sx={{borderBottom: "6px solid #03d1d1"}}>
                        I dolci di Natale
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Tronchetto di natale
                        <img src={'/images/food-icons/icon-piemonte.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Panettone classico
                        <img src={'/images/food-icons/icon-lombardia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Panettone classico Vegan
                        <img src={'/images/food-icons/icon-lombardia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-vegano.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Pandoro
                        <img src={'/images/food-icons/icon-veneto.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Pandoro Vegan
                        <img src={'/images/food-icons/icon-veneto.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-vegano.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
            </ul>
        </>
    );
}
