import React from "react";
import {Box, Slide, Stack, Typography} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";

import clap from '../assets/images/emoji-1-icon.svg'
import thumb from '../assets/images/emoji-2-icon.svg'
import heart from '../assets/images/emoji-3-icon.svg'
import emoji from '../assets/images/emoji-4-icon.svg'
import {emojisplosion} from "emojisplosion";
import {API_URL} from "../config";
import {useMediaQuery} from "@material-ui/core";


const ReactionsLive = ({url, idPlaying, eventPlaying, user}) => {
    const {id} = useParams()

    async function postDataApplausi(e, type, emoji) {
        emojisplosion({
            emojiCount:5,
            emojis: [emoji],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        await axios.post(`${API_URL}/${id}/reactions?type=${type}`, {user: user.uid})
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    const ButtonItem = ({type, img, emoji, color}) => (
        <Box id='clapButton' disabled={!eventPlaying} onClick={(e) => postDataApplausi(e, type, emoji)}
             justifyContent={'center'} alignItems={'center'} direction={"column"}
             pt={'12px'}
            //borderRadius={"0 16px"} border={'1.5px solid '+color}
             sx={{
                 //bgcolor: pleshDark.palette.accent.main,//color,
                 width: 65,
                 height: 65,
                 cursor: 'pointer',
                 //boxShadow: '6px 5px 6px 0px #37373761'
             }}
        >
            <img style={{margin: 'auto', height:'50px'}} src={img} />
        </Box>
    )

    const matches = useMediaQuery('(max-height:680px)');

    return (
        <Stack alignItems={'center'} sx={{position: 'relative',}}>
            {/*<Typography gutterBottom variant={'caption'}>Send your reactions</Typography>*/}
            <Typography textAlign={'center'} fontSize={12} fontWeight={'normal'} color={'primary'} >
                INTERAGISCI CON LO SCHERMO DEL PALCO!
            </Typography>

            <Slide in direction={"up"} timeout={1000}>
                <Stack mx={4} mb={2} direction={'row'} display={'flex'} justifyContent={'space-between'} spacing={2}>
                    {
                        reactions.map((reaction) => (
                            <ButtonItem key={reaction.id} type={reaction.id} img={reaction.img} emoji={reaction.emoji} color={reaction.color}/>
                        ))
                    }
                </Stack>
            </Slide>
        </Stack>
    )
}
export default ReactionsLive

const reactions = [
    {
        id: 'clap',
        emoji: '👏',
        img: clap,
        color: '#ca3457'
    },
    {
        id: 'thumb',
        emoji: '👍',
        img: thumb,
        color: '#cd98ee'
    },
    {
        id: 'heart',
        emoji: '❤️',
        img: heart,
        color: '#7fd977',
    },
    {
        id: 'emoji',
        emoji: '😄',
        img: emoji,
        color: '#fde357'
    }
]