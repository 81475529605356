import React from "react";
import {Box, List, ListItem, Typography} from "@mui/material";

export default function DinnerCollapseContent() {
    return (
        <>
            <List>
                <ListItem sx={{ paddingLeft: "10%" }}>
                    <Typography fontWeight={"bold"} sx={{ borderBottom: "6px solid #03d1d1" }}>
                        Maritozzi salati con:
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{ color: "#fff" }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Stracciatella e alici
                        <img src={'/images/food-icons/icon-puglia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{ color: "#fff" }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Sugo di amatriciana
                        <img src={'/images/food-icons/icon-lazio.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{ color: "#fff" }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Zucchine alla scapece
                        <img src={'/images/food-icons/icon-campania.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-veg.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>

                </li>
            </ul>
            <List>
                <ListItem sx={{ paddingLeft: "10%" }}>
                    <Typography fontWeight={"bold"} sx={{ borderBottom: "6px solid #03d1d1" }}>
                        Croissant salati ripieni di:
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{ color: "#fff" }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Stracciatella e alici
                        <img src={'/images/food-icons/icon-puglia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Sugo di amatriciana
                        <img src={'/images/food-icons/icon-lazio.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Zucchine alla scapece
                        <img src={'/images/food-icons/icon-campania.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-vegano.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>

                </li>
            </ul>

            <List>
                <ListItem sx={{ paddingLeft: "10%" }}>
                    <Typography fontWeight={"bold"} sx={{ borderBottom: "6px solid #03d1d1" }}>
                        Tasca di pizza farcita con:
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Salsiccia e friarielli
                        <img src={'/images/food-icons/icon-campania.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Baccalà alla trasteverina
                        <img src={'/images/food-icons/icon-lazio.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Parmigiana di melanzane
                        <img src={'/images/food-icons/icon-calabria.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-veg.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
            </ul>

            <List>
                <ListItem sx={{ paddingLeft: "10%" }}>
                    <Typography fontWeight={"bold"} sx={{ borderBottom: "6px solid #03d1d1" }}>
                        Pinsa decorata con:
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Salsiccia e friarielli
                        <img src={'/images/food-icons/icon-campania.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Baccalà alla trasteverina
                        <img src={'/images/food-icons/icon-lazio.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Parmigiana di melanzane
                        <img src={'/images/food-icons/icon-calabria.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-veg.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
            </ul>

            <List>
                <ListItem sx={{paddingLeft: "10%", display: 'flex', alignItems: 'center'}}>
                    <Typography fontWeight={"bold"} sx={{borderBottom: "6px solid #03d1d1"}}>
                        Il cartoccio di fritti
                    </Typography>
                    <img src={'/images/food-icons/icon-lazio.svg'} alt={''} width={'30px'}
                         style={{marginLeft: '5px'}}/>
                    <img src={'/images/food-icons/icon-marche.svg'} alt={''} width={'30px'}
                         style={{marginLeft: '5px'}}/>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>Supplì al telefono</li>
                <li style={{color: "#fff"}}>Mini panzerotti mozzarella e pomodoro</li>
                <li style={{color: "#fff"}}>Olive ascolane</li>
                <li style={{color: "#fff"}}>Crocchetta di patate</li>
            </ul>

            <List>
                <ListItem sx={{paddingLeft: "10%", display: 'flex', alignItems: 'center'}}>
                    <Typography fontWeight={"bold"} sx={{borderBottom: "6px solid #03d1d1"}}>
                        Il cartoccio di fritti siciliano
                    </Typography>
                    <img src={'/images/food-icons/icon-sicilia.svg'} alt={''} width={'30px'}
                         style={{marginLeft: '5px'}}/>
                    <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                         style={{marginLeft: '5px'}}/>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>Arancini al ragù</li>
                <li style={{color: "#fff"}}>Arancini al ragù bianco</li>
                <li style={{color: "#fff"}}>Mozzarelle panate</li>
            </ul>

            <List>
                <ListItem sx={{paddingLeft: "10%" }}>
                    <Typography fontWeight={"bold"} sx={{ borderBottom: "6px solid #03d1d1" }}>
                        Crostini di polenta con:
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Stracci di burrata e mortadella
                        <img src={'/images/food-icons/icon-emilia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>

                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Spinaci al burro e parmigiano
                        <img src={'/images/food-icons/icon-emilia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-veg.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>

                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Baccalà mantecato
                        <img src={'/images/food-icons/icon-veneto.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Spinaci saltati
                        <img src={'/images/food-icons/icon-toscana.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-gluten.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-veg.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>

                </li>
            </ul>
        </>
    );
}
