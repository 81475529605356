import React from "react";
import {List, ListItem, styled, Typography} from "@mui/material";
import {THEME_CONFIG} from "../../../config";

const {poll} = THEME_CONFIG

export default function QuizQuestion({risposte, corretta, hideResult = false, answer, sendAnswer, isPoll}) {
    const RispostaStyled = styled(ListItem)({
        background: 'transparent',
        margin: '1rem 0',
        cursor: 'pointer',
    })

    const computeBkg = (r) => {
        if(!isPoll && hideResult === false) {
            if (answer !== '') {
                if (corretta === r) {
                    return poll.correct
                } else if (answer === r  && answer !== corretta){
                    return poll.wrong
                }
            }
        } else {
            if(answer === r) {
                return '#03d1d0'
            }
            else {
                return 'transparent'
            }
        }
    }

    const computeAnswer = (r) => {
        if (answer === r) {
            return true
        }
    }

    const Risposte = () => (
        risposte.map((r, i) => (
            <RispostaStyled key={i} style={{
                width: '100%',
                background: computeBkg(r),
                border: computeAnswer(r) ? 'none' : '3px solid #fff',
                borderRadius: computeAnswer(r) ? '0 0 0 25px' : 0,
                color: 'white',
            }} onClick={() => rispondi(r)}>
                <Typography variant={"h6"} fontWeight='400' textAlign={'center'} width={'100%'} p={1}>
                    {r}
                </Typography>
            </RispostaStyled>
        ))
    )

    const rispondi = (r) => {
        if(isPoll) {
            sendAnswer(r)
        } else {
            if(answer === ''){
                sendAnswer(r)
            }
        }
    }

    return (
        <List>
            <Risposte/>
        </List>
    )
}
