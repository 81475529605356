import {createTheme, responsiveFontSizes} from "@mui/material";
import {THEME_CONFIG} from "../config";

const {gradients, primary, secondary, accent, background, mode, text} = THEME_CONFIG

const pleshDark = responsiveFontSizes(createTheme({
    typography: {
      fontFamily: ['Iqos']
    },
    components:{
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    background: background,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                },
            },
        },
        MuiDialog:{
            styleOverrides:{
                paper:{
                    background: background,
                }
            }
        },
        MuiTextField:{
            styleOverrides:{
                root:{
                    '& fieldset':{
                        borderWidth:'3px !important',
                        border:'none'
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                    border:'3px solid #fff',
                    borderRadius: '0 0 0 25px',
                    '& .MuiOutlinedInput-root': {
                        color: text.primary,
                        '& fieldset': {
                            borderWidth: '2px',
                        },
                        '&:hover fieldset': {
                        },
                        '&.Mui-focused fieldset': {
                        },
                    },
                },
                paper: {
                    borderRadius: '1rem',
                    backgroundColor: background,
                    '& .MuiAutocomplete-option': {
                        fontSize: '16px',
                        color: text.primary,
                        '&:hover': {
                            backgroundColor: secondary,
                            color: '#fff',
                        },
                        '&[aria-selected="true"]': {
                            backgroundColor: accent,
                            color: '#fff',
                        },
                    },
                },
            },
        },
        MuiButton:{
            variants: [
                //siNo
                {
                    props: {variant:'siNo'},
                    style:{
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        border: 0,
                        color: "white !important",
                    },
                },
                {
                    props: {variant:'siNo', risposta: 'si'},
                    style:{
                        background: gradients.siNo[0],
                    },
                },
                {
                    props: {variant:'siNo', risposta: 'no'},
                    style:{
                        background: gradients.siNo[1],
                    },
                },
                //sentiment
                {
                    props: {variant:'sentiment'},
                    style:{
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        border: 0,
                        color: "white !important",
                    },
                },
                {
                    props: {variant:'sentiment', risposta: 'daccordo'},
                    style:{
                        background: gradients.sentiment[0],
                    },
                },
                {
                    props: {variant:'sentiment', risposta: 'nonDaccordo'},
                    style:{
                        background: gradients.sentiment[1],
                    },
                },
                {
                    props: {variant: 'default'},
                    style:{
                        height: '100px',
                        width:'80%',
                        fontSize: '18px',
                        background: 'linear-gradient(90deg, '+gradients.default[0]+' 0%, '+gradients.default[1]+')',
                        border:'none',
                        color: '#f1ebe9',
                        borderRadius: '2rem',
                        borderWidth: '3px',
                    }
                },
                {
                    props: {variant: 'clap'},
                    style:{
                        height: '100px',
                        width:'80%',
                        fontSize: '18px',
                        background: 'linear-gradient(90deg, '+gradients.applausometro[0]+' 0%, '+gradients.applausometro[1]+')',
                        border:'none',
                        color: '#f1ebe9',
                        borderRadius: '2rem',
                        borderWidth: '3px',
                    }
                },
                {
                    props: {variant: 'wordcloud'},
                    style:{
                        background: 'linear-gradient(90deg, '+gradients.wordcloud[0]+' 0%, '+gradients.wordcloud[1]+')',
                        fontSize: '18px',
                        border:'none',
                        fontWeight:'bold',
                        color: '#fff',
                        borderRadius: '2rem',width: '100%', height: '50px'
                    }
                },
                {
                    props: {variant: 'home-question'},
                    style:{
                        background: 'ttransparent',
                        border:'3px solid #fff',
                        color: '#fff',
                        borderRadius: '0rem',
                        width: '20vw',
                        height: '20vw',
                    }
                },
                {
                    props: {variant: 'qna'},
                    style:{
                        background: 'linear-gradient(90deg, '+gradients.qna[0]+' 0%, '+gradients.qna[1]+')',
                        fontSize: '18px',
                        border:'none',
                        fontWeight:'bold',
                        color: '#000',
                        borderRadius: '2rem',width: '100%', height: '50px'
                    }
                },
                {
                    props: {variant: 'iqos'},
                    style:{
                        background: 'transparent',
                        fontSize: '18px',
                        border:'3px solid #fff',
                        fontWeight:'normal',
                        padding:'0px',
                        color: '#fff',
                        borderRadius: '0px',width: '80%', height: '70px',
                        '&:hover': {
                            color: '#34303d', // Cambia colore del testo
                            borderRadius: '0 0 0 25px', // Aggiunge il border radius solo in basso a sinistra
                            border:'none',
                            backgroundColor:'#00d1d2'
                        },
                    }
                }
            ]
        },
    },
    palette: {
        mode: mode,
        primary: {
            main: primary,
        },
        secondary: {
            main: secondary,
        },
        accent: {
            main: accent,
        },
        text: {
            primary: text,
        },
        background:{
            default:background
        }
    },
}));

export default pleshDark
