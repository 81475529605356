import React from "react";
import {Box, List, ListItem, Typography} from "@mui/material";

export default function WelcomeCollapseContent() {
    return (
        <>
            {/*<List>
                <ListItem sx={{ paddingLeft: "10%" }}>
                    <Typography fontWeight={"bold"} sx={{ borderBottom: "6px solid #03d1d1" }}>
                        Beverage
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{ color: "#fff" }}>Soft Drinks</li>
                <li style={{ color: "#fff" }}>Succhi di frutta</li>
                <li style={{ color: "#fff" }}>Acqua minerale e naturale</li>
                <li style={{ color: "#fff" }}>Caffè espresso e decaffeinato</li>
                <li style={{ color: "#fff" }}>Orzo</li>
                <li style={{ color: "#fff" }}>Ginseng</li>
                <li style={{ color: "#fff" }}>Selezione di the</li>
                <li style={{ color: "#fff" }}>Latte di soia e senza lattosio</li>
            </ul>*/}
            <List>
                <ListItem sx={{ paddingLeft: "10%" }}>
                    <Typography fontWeight={"bold"} sx={{ borderBottom: "6px solid #03d1d1" }}>
                        Welcome sweets
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{ color: "#fff" }}>Biscotti al burro</li>
                <li style={{ color: "#fff" }}>Frollini al cioccolato</li>
                <li style={{ color: "#fff" }}>Bon bon al cocco</li>
            </ul>
            <List>
                <ListItem sx={{ paddingLeft: "10%" }}>
                    <Typography fontWeight={"bold"} sx={{ borderBottom: "6px solid #03d1d1" }}>
                        Welcome snacks
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Mini tramezzini
                        <img src={'/images/food-icons/icon-piemonte.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Focaccine con prosciutto crudo
                        <img src={'/images/food-icons/icon-emilia.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Mini focaccia genovese con crema di pecorino, pomodori secchi e melanzane
                        <img src={'/images/food-icons/icon-liguria.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                        <img src={'/images/food-icons/icon-veg.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
            </ul>
        </>
    );
}
