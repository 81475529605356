import React from "react";
import {Box, List, ListItem, Typography} from "@mui/material";

export default function DrinkCollapseContent() {
    return (
        <>
            <List>
                <ListItem sx={{paddingLeft: "10%"}}>
                    <Typography fontWeight={"bold"} sx={{borderBottom: "6px solid #03d1d1"}}>
                        Caffetteria
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>Succhi di frutta</li>
                <li style={{color: "#fff"}}>Acqua minerale</li>
                <li style={{color: "#fff"}}>Caffè espresso</li>
                <li style={{color: "#fff"}}>Caffè decaffeinato</li>
                <li style={{color: "#fff"}}>Orzo</li>
                <li style={{color: "#fff"}}>Ginseng</li>
                <li style={{color: "#fff"}}>Selezione di thè</li>
                <li style={{color: "#fff"}}>Latte di soia</li>
                <li style={{color: "#fff"}}>Latte senza lattosio</li>
            </ul>
            <List>
                <ListItem sx={{paddingLeft: "10%"}}>
                    <Typography fontWeight={"bold"} sx={{borderBottom: "6px solid #03d1d1"}}>
                        Soft drink
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>Coca-Cola</li>
                <li style={{color: "#fff"}}>Coca-Cola Zero</li>
                <li style={{color: "#fff"}}>Fanta</li>
            </ul>
            <List>
                <ListItem sx={{paddingLeft: "10%"}}>
                    <Typography fontWeight={"bold"} sx={{borderBottom: "6px solid #03d1d1"}}>
                        Birra
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Peroni
                        <img src={'/images/food-icons/icon-lazio.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Ichnusa
                        <img src={'/images/food-icons/icon-sardegna.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
            </ul>
            <List>
                <ListItem sx={{paddingLeft: "10%"}}>
                    <Typography fontWeight={"bold"} sx={{borderBottom: "6px solid #03d1d1"}}>
                        Vino
                    </Typography>
                </ListItem>
            </List>
            <ul>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Vino bianco Ribolla Gialla
                        <img src={'/images/food-icons/icon-friuli.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Vino rosso Montefalco
                        <img src={'/images/food-icons/icon-umbria.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
                <li style={{color: "#fff"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Spumante Tener Banfi
                        <img src={'/images/food-icons/icon-piemonte.svg'} alt={''} width={'30px'}
                             style={{marginLeft: '5px'}}/>
                    </Box>
                </li>
            </ul>
        </>
    );
}
