import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog, DialogContent,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {firebase} from "../firebase/clientApp";
import {Edit} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../config";
import {CustomAlert} from "./shared/CustomAlert";
import {premiumOptions} from "../assets/data/premiumOptions";

export const DisplayName = ({userData, setUserData}) => {
    const navigate = useNavigate();

    const {id} = useParams()
    const [state, setState] = useState({displayName: '', teamName: ''})
    const [error, setError] = useState(false)
    const [setting, setSetting] = useState(false)
    const [editing, setEditing] = useState(false)
    const [selection, setSelection] = useState(null);

    useEffect(() => {
        if (userData) {
            setState(userData)
            if(userData?.displayName) {
                setSelection(userData.displayName)
            }
        }
        console.info(userData);
    }, [userData])

    const handleSetDisplayName = (event, newValue) => {
        console.info(newValue); // Stampa il nuovo valore selezionato
        setState({ ...state, displayName: newValue ? newValue.label : '' });
        console.info(state)
    };

    const handleSetTeam = (event) => {
        setState({...state, teamName: event.target.value})
    }

    const handleSelection = (selected) => {
        //TODO: se selected != premium autentica direttamente con nome guest-staff
        if(selected!=='premium') {
            submitGuestStaff(selected)
        }
        setSelection(selected);
    }

    const submitGuestStaff =  async (value) => {
        setSetting(true)
        let uid = firebase.auth().currentUser.uid
        await axios.post(`${API_URL}/events/${id}/users`, {user: uid, displayName: value, teamName: value})
            .then(res => {
                setUserData({displayName: value, teamName: value})
                setError("")
                setEditing(false)
            })
            .catch((err) => {
                //console.log("NickName già preso!!!", err?.response?.data?.message ?? "There was an error")
                setError(err?.response?.data?.message ?? "There was an error")
            })
            .finally(() => {
                setSetting(false)
            })
    }

    const submitDisplayName = async (event) => {
        event.preventDefault()
        setSetting(true)
        let uid = firebase.auth().currentUser.uid
        await axios.post(`${API_URL}/events/${id}/users`, {user: uid, displayName: state.displayName, teamName: state.displayName})
            .then(res => {
                    setUserData({displayName: state.displayName ?? '', teamName: state.displayName ?? ''})
                    setError("")
                    setEditing(false)
            })
            .catch((err) => {
                console.log("NickName già preso!!!", err?.response?.data?.message ?? "There was an error")
                setError(err?.response?.data?.message ?? "There was an error")
            })
            .finally(() => {
                setSetting(false)
            })
    }

    return (
        <Box >
            {selection === null ?
                <Box>
                    <Typography textAlign={'center'} fontSize={20} color={'primary'}>
                        Benvenuto sull’app dell’IQOS <br/>
                        Premium Partner FEST!
                    </Typography>
                    <Typography textAlign={'center'} fontSize={20} color={'primary'} fontWeight={'bold'} sx={{pt:'2vh'}}>
                        Per accedere, seleziona la tua <br/>
                        categoria di appartenenza <br/>
                        tra le opzioni disponibili:
                    </Typography>
                    <Stack spacing={'2vh'} sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', pt:'2vh'}}>
                        <Button variant={'iqos'} onClick={() => handleSelection('premium')}>
                            PREMIUM PARTNER E ACCOMPAGNATORI
                        </Button>
                        <Button variant={'iqos'} onClick={() => handleSelection('staff')}>
                            PHILIP MORRIS ITALIA
                            STAFF
                        </Button>
                        <Button variant={'iqos'} onClick={() => handleSelection('guest')}>
                            GUEST
                        </Button>
                    </Stack>
                    <Typography textAlign={'center'} fontSize={16} color={'#cbcbcb'} fontWeight={'normal'} sx={{pt:'3vh'}}>
                        <a style={{color:'#cbcbcb', textDecoration:'none'}} href={'https://www.pmiprivacy.com/italy/it/general-enquirer/'} target={'_blank'}>Informativa Privacy</a>
                    </Typography>
                    <Typography textAlign={'center'} fontSize={16} color={'#cbcbcb'} fontWeight={'normal'} sx={{ pt: '1vh' }}>
                        <a
                            style={{ color: '#cbcbcb', textDecoration: 'none' }}
                            href={'/piano-sicurezza.pdf'}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            Piano sicurezza
                        </a>
                    </Typography>
                </Box>
                :
                <>
                    {
                        (editing || userData.displayName==='' || !userData.displayName) ?
                            <>
                                { editing ?
                                    <Dialog open={editing} fullWidth maxWidth={'md'} onClose={() => setEditing(false)}>
                                        <DialogContent>
                                            <form onSubmit={submitDisplayName} style={{paddingTop: '2rem'}}>
                                                <Autocomplete
                                                    options={premiumOptions}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={handleSetDisplayName}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            required
                                                            value={state.displayName}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                style: {fontSize: '1.2rem'}
                                                            }}
                                                            placeholder="Cerca il tuo nome utente"
                                                            id="displayName"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: (
                                                                    <Box
                                                                        component="span"
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            marginRight: '8px',
                                                                        }}
                                                                    >
                                                                        {/* Immagine SVG personalizzata */}
                                                                        <img
                                                                            src={'/images/icons/research-icon.svg'}
                                                                            alt="Search Icon"
                                                                            style={{width: '20px', height: '20px'}}
                                                                        />
                                                                    </Box>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                    sx={{width: '100%'}}
                                                />
                                                <Box pt={4} display={'flex'} justifyContent={'center'}>
                                                    {editing &&
                                                        <Button sx={{color: 'white'}} onClick={() => setEditing(false)}>
                                                            Annulla
                                                        </Button>}
                                                    {setting ? <CircularProgress/> :
                                                        <Button variant={'contained'} type={'submit'}
                                                                sx={{fontSize: '1.2rem'}}
                                                                disabled={
                                                                    (editing && userData.displayName === state.displayName) || (!state.displayName)
                                                                }>
                                                            conferma
                                                        </Button>}
                                                    <CustomAlert open={!!error} message={error} setOpen={setError}
                                                                 severity={'error'}/>
                                                </Box>
                                            </form>
                                        </DialogContent>
                                    </Dialog>
                                    :
                                    <Box>
                                        <Typography textAlign={'center'} fontSize={20} color={'primary'}
                                                    fontWeight={'bold'}>
                                            Sei un Premium Partner? <br/>
                                            Seleziona il tuo nome.
                                        </Typography>
                                        <Typography textAlign={'center'} fontSize={20} color={'primary'}
                                                    fontWeight={'bold'}
                                                    sx={{pt: 4}}>
                                            Sei un Accompagnatore? <br/>
                                            Usa lo stesso nome <br/>
                                            del “tuo” Premium Partner.
                                        </Typography>
                                        <form onSubmit={submitDisplayName} style={{paddingTop: '2rem'}}>
                                            <Autocomplete
                                                options={premiumOptions}
                                                getOptionLabel={(option) => option.label}
                                                onChange={handleSetDisplayName}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        required
                                                        value={state.displayName}
                                                        inputProps={{...params.inputProps, style: {fontSize: '1.2rem'}}}
                                                        placeholder="Cerca il tuo nome utente"
                                                        id="displayName"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <Box
                                                                    component="span"
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        marginRight: '8px',
                                                                    }}
                                                                >
                                                                    {/* Immagine SVG personalizzata */}
                                                                    <img
                                                                        src={'/images/icons/research-icon.svg'}
                                                                        alt="Search Icon"
                                                                        style={{width: '20px', height: '20px'}}
                                                                    />
                                                                </Box>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                sx={{width: '100%'}}
                                            />
                                            <Box pt={4} display={'flex'} justifyContent={'center'}>
                                                {editing &&
                                                    <Button sx={{color: 'white'}} onClick={() => setEditing(false)}>
                                                    Annulla
                                                </Button>}
                                                {setting ? <CircularProgress/> :
                                                    <Button variant={'contained'} type={'submit'} sx={{fontSize: '1.2rem'}}
                                                            disabled={
                                                                (editing && userData.displayName === state.displayName) || (!state.displayName)
                                                            }>
                                                        conferma
                                                    </Button>}
                                                <CustomAlert open={!!error} message={error} setOpen={setError}
                                                             severity={'error'}/>
                                            </Box>
                                        </form>
                                    </Box>
                                }
                            </>
                            :
                            <>
                                {
                                    <Stack my={2} direction={'row'} justifyContent={'center'} alignItems={'center'}
                                        spacing={1}
                                        sx={{
                                            position: 'absolute',
                                            left: '50%',
                                            transform: 'translate(-50%)',
                                            width: '100%',
                                            top: '0.5vh'
                                        }}
                                    >
                                        <img
                                            src={'/images/icons/edit-icon.svg'}
                                            alt="Edit Icon"
                                            style={{width: '20px', height: '20px'}}
                                            onClick={() => {
                                                setSelection(null)
                                                setUserData({displayName: '', teamName: ''})
                                            }}
                                        />
                                        <Stack>
                                            <Typography gutterBottom textAlign={'center'} fontSize={12} color={'primary'}>
                                                {userData.displayName==='staff' ? 'Philip Morris Italia Staff' : (userData.displayName==='guest' ? 'Guest' : userData.displayName)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                }
                            </>

                    }
                </>
            }


        </Box>
    )
}
